 .achievements-section {
  padding-top: ac(135px, 70px);
  padding-bottom: ac(135px, 70px);
  background-color: var(--blue-green);
  background-image: url("../images/decor-items/decor-opacity-55.svg");
  background-repeat: no-repeat;
  background-size: 110% 110%;

  &.fixed-bg {
    background-attachment: fixed;
  }
}

.clients-section {
  padding: 80px 0;

  .text-left {
    position: relative;
    z-index: 10;
  }
}

.achievements-list {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 274px);
  justify-content: center;
  grid-auto-rows: 1fr;

  @mixin media 690 {
    .achievements-card {
      min-height: 160px;
    }
  }

  @mixin media 620 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.achievements-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px ac(35px, 21px) 26px 21px;
  background: var(--light-grey);

  &__title {
    width: fit-content;
    margin-left: -30px;
    margin-bottom: 30px;
    word-break: break-word;
    filter: drop-shadow(5px 10px 15px var(--shadow-color));
    color: var(--white);
    background: var(--red);
    padding: 16px 20px 15px ac(26px, 16px);
    clip-path: polygon(0 0, 100% 0, 89% 100%, 0 100%, 0 100%);
    min-width: 270px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .count-block {
      color: var(--white);
      font-size: ac(45px, 25px);
      letter-spacing: -0.25px;
      line-height: 1.1;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;

      span {
        &:first-child {
          margin-right: 9px;
        }
      }
    }
  }

  &__text {
    max-width: 160px;
    p {
      font-size: ac(21px, 16px);
      font-weight: 500;
      line-height: 147.62%;
      color: var(--theme-black);
    }
  }
}

.hiring-section {
  padding-top: ac(135px, 70px);
  padding-bottom: ac(70px, 35px);

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .text-heading {
    max-width: 580px;

    h3 {
      letter-spacing: -0.25px;
      font-size: ac(32px, 22px);
      text-transform: uppercase;
      text-align: center;
      color: var(--blue-green);
      line-height: 150%;
    }
  }

  .text-content {
    max-width: 768px;
    text-align: center;
    p,
    li,
    a {
      font-size: ac(21px, 16px);
      font-weight: 500;
      line-height: 147.62%;
      color: var(--theme-black);
    }
  }

  .hiring-slider {
    padding-top: 40px;
  }

  @mixin media 960 {
    padding-bottom: 120px;
  }

  @mixin media 769 {
    padding-bottom: 170px;
  }
}

.hiring-slider {
  max-width: calc(100% + 16px);
  width: calc(100% + 16px);
  margin-left: -8px;

  @mixin min-media 961 {
    .swiper-pagination,
    .navigation {
      display: none;
    }
  }

  .swiper-slide {
    padding: 8px;
    height: auto;
  }

  @mixin media 960 {
    overflow: visible;
    clip-path: inset(0 -100vw -150px -5px);
  }

  @mixin media 650 {
    margin-left: 0;
    width: 320px;
    max-width: 320px;
    overflow: visible;

    .swiper-pagination {
      width: 89vw;
    }

    .navigation {
      width: 89vw;
      left: 0;
      transform: translateX(0);
      justify-content: center;
    }

    .swiper-slide {
      padding: 0;
    }
  }

  @mixin media 375 {
    width: 100%;
    max-width: 100%;

    .swiper-pagination {
      width: 100%;
    }

    .navigation {
      width: 100%;
    }
  }
}

.hiring-card {
  position: relative;
  padding-bottom: ac(43px, 30px);
  background: var(--light-grey);
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__photo {
    width: 100%;
    height: ac(187px, 160px);
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0%, 78% 100%, 0% 100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.2s ease-in-out;
    }
  }

  &__title {
    margin-top: -48px;
    margin-bottom: ac(30px, 25px);
    filter: drop-shadow(5px 10px 15px var(--shadow-color));
    color: var(--white);
    background: var(--blue-green);
    padding: ac(20px, 15px) 20px ac(20px, 15px) ac(22px, 18px);
    min-height: ac(81px, 70px);
    clip-path: polygon(0 0%, 100% 0, 89% 100%, 0 100%, 0 100%);
    min-width: 253px;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h4 {
      font-size: ac(25px, 18px);
      letter-spacing: -0.25px;
      line-height: 124%;
      word-break: break-word;
    }

    @mixin media 1220 {
      min-width: auto;
      width: 80%;
    }
  }

  &__text {
    padding-right: ac(25px, 18px);
    padding-left: ac(25px, 18px);
    width: 100%;

    p,
    li,
    a {
      font-size: ac(21px, 16px);
      font-weight: 500;
      line-height: 214.29%;
      color: var(--theme-black);
    }

    @mixin media 1220 {
      br {
        display: none;
      }
    }
  }
}

.case-studies-section {
  padding-top: ac(70px, 35px);
  padding-bottom: ac(195px, 100px);
  background: var(--white);

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }


  &__info {
    margin-top: ac(18px, 0px);
    width: 25%;
    max-width: 278px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    position: relative;
    z-index: 20;

    @mixin media 1025 {
      width: 100%;
      max-width: 600px;
      padding-bottom: 40px;
    }
  }

  &__title {
    margin-bottom: 5px;
    h3 {
      color: var(--theme-black);
      letter-spacing: -0.25px;
    }
  }

  .text-content {
    padding-bottom: ac(32px, 25px);

    p,
    li,
    a {
      font-size: ac(21px, 16px);
      font-weight: 500;
      line-height: 147.62%;
      color: var(--theme-black);
    }
  }

  .slider-wrap {
    width: 66.31%;

    @mixin media 1025 {
      width: 100%;
    }
  }

  .case-studies-slider {
    max-width: 100%;
    width: 100%;
  }

  @mixin tab {
    overflow: visible;
    z-index: 2;

    &__wrap {
      flex-direction: column;
    }

    .case-studies-slider {
      overflow: visible;
      clip-path: none;
    }
  }

  @mixin media 769 {
    padding-bottom: 150px;
  }
}

.case-studies-slider {
  overflow: visible;
  clip-path: inset(0 -100vw -10px -5px);
}

.case-studies-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @mixin aspect-ratio-block 375, 330;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  background: var(--white);

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-47%);
    width: 100%;
    max-width: 98%;
    max-height: calc(100% - 110px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: transform 0.3s ease-in-out;
    }
  }

  .shadow-btn {
    position: absolute;
    left: 0;
    bottom: 11px;
  }

  &:hover {
    img {
      transform: scale(0.85);
    }
  }

  @mixin media 650 {
    .btn.xxl {
      width: 170px;
      span:after {
        right: 11px;
        transform: skewX(-26deg);
      }
    }
  }
}
@mixin media 1200 {
  .clients-section.about-page {
    padding: 0;
  }
}
