.redline-popup,
.case-study-popup {
  font-family: var(--font-main);
  overflow: visible;
  max-height: 100%;
  position: relative;
  max-width: 556px;
  background: var(--white);
  color: var(--theme-black);
  padding: 25px ac(42px, 15px) ac(98px, 20px)
    ac(47px, 15px);
  box-shadow: var(--main-shadow);

  input {
    height: ac(60px, 50px);
  }

  .subtext {
    margin-bottom: ac(28px, 23px);
  }

	display: flex;
	flex-direction: column;

  .popup {
    &__title {
      background: var(--red);
      clip-path: polygon(0 0, 100% 0, 93% 100%, 0 100%, 0 100%);
      color: var(--white);
      filter: drop-shadow(5px 10px 15px var(--shadow-color));
      font-size: ac(32px, 22px);
      left: -15px;
      letter-spacing: -0.25px;
      padding: 6px 42px 6px 27px;
      margin-left: ac(-65px, -20px);
      min-width: 268px;
      max-width: 80%;
      width: fit-content;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      @mixin media 376 {
        max-width: 215px;
        min-width: auto;
      }
    }

    &__btn-close {
      background-image: url("../images/icons/close.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 26px;
      height: 26px;
      cursor: pointer;
      top: 25px;
      right: 26px;
      transition: transform 0.3s ease-in-out;

      .cross-clip {
        display: none;
      }

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__container {
      max-height: 70vh;
      max-height: calc(var(--vh, 1vh) * 70);
      padding-right: 16px;
      transition: max-height 0.3s ease;
			margin: 0 -16px 0 0;

      /*.simplebar-scrollbar::before {
        width: 5px;
        background: var(--dark-blue);
        border-radius: 5px;
      }*/

      /* &::-webkit-scrollbar-thumb {
       
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--dark-blue);
      } */

      .simplebar-track.simplebar-vertical {
        width: 4px;
        background: transparent;
        border-radius: 2px;

        .simplebar-scrollbar {
          background: var(--theme-black);
          border-radius: 2px;

          &:before {
            content: none;
          }
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .buttons-wrap {
        .shadow-wrap {
          filter: none;

        }
      }
    }
  }

  .sign-up {
    width: 100%;

    .select2-container--open .select2-dropdown--below {
      max-height: 141px;
    }

    input,
    .upload-input {
      margin-bottom: ac(19px, 8px);
    }

    .upload-input {
      margin-bottom: 25px;
    }

    .select2-container {
      width: 100% !important;
      margin-bottom: ac(28px, 20px);
    }

    .agreement {
      margin-bottom: 26px;

      .checkBox {
        span {
          padding-left: 20px;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    .buttons-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn {
      font-weight: 600;
    }
  }

  &.case-study-popup {
    max-width: 804px;
    width: 100%;
    padding: 25px ac(15px, 10px) ac(16px, 20px)
      ac(53px, 18px);

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      /*
      transform: translateX(-41.8%) translateY(-44.5%) rotate(-186deg);
      */
      width: 100%;
      height: ac(356px, 223px);
      background-image: url("../images/decor-items/popup-decor.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right top;
      opacity: 0.55;
      z-index: -1;
    }

    .popup {
      &__title {
        background: var(--theme-black);
        max-width: ac(480px, 400px);
        padding-top: 7px;
        padding-bottom: 4px;
        top: 26px;

        @mixin media 650 {
          max-width: 80%;
        }
      }

      &__container {
        padding-right: ac(20px, 12px);
        max-width: 100%;
      }
    }

    @mixin tab {
      width: 96%;
    }
  }

  &.apply-popup {
    max-width: 100%;
    padding-bottom: 40px;

    .sign-up__inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;

      input {
        margin: 0;
      }

      @mixin media 650 {
        grid-template-columns: 1fr;
      }
    }

    .upload-input {
      grid-column: -1 / 1;
      justify-content: center;
    }

    .upload-input__file-name {
      width: auto;
      max-width: 200px;
    }

    .popup__container {
      max-height: 50vh;
      max-height: calc(var(--vh, 1vh) * 50);

      @mixin media 650 {
        max-height: 100%;
      }

      @mixin max-height 650 {
        max-height: 55vh;
        max-height: calc(var(--vh, 1vh) * 55);
      }
    }

    @mixin max-height 550 {
      top: 50%;
    }
  }

  @mixin max-height 860 {
    max-width: 100%;
    padding-bottom: 25px;
  }

  @mixin max-height 700 {
    .popup__container {
      max-height: 50vh;
      max-height: calc(var(--vh, 1vh) * 50);
    }
  }

  @media only screen and (max-height: 860px) and (max-width: 650px) {
    /*max-height: 100vh;
    height: 100vh; !* Fallback for browsers that do not support Custom Properties *!
    height: calc(var(--vh, 1vh) * 100);*/
    
    .upload-input {
      justify-content: flex-start;
    }

    .sign-up__inputs {
      grid-template-columns: 1fr;
    }

    .popup__container {
      max-height: calc(var(--vh, 1vh) * 74);
    }
  }

  @mixin max-height 560 {
    .popup__container {
      max-height: calc(var(--vh, 1vh) * 70);
    }
  }
}

.case-study-content {
  &__logo {
    margin-bottom: ac(39px, 30px);
    max-width: 40%;

    img {
      object-fit: contain;
      object-position: left;
    }
  }

  &__info {
    width: 100%;
    padding-bottom: ac(36px, 20px);
  }

  &__info-title {
    margin-bottom: 3px;

    h3 {
      font-size: ac(21px, 16px);
      font-weight: 500;
      line-height: 145%;
      color: var(--theme-black);
      text-transform: none;
    }
  }

  &__additional-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__additional-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(41px, 20px);

    &:not(:last-child) {
      margin-right: ac(40px, 15px);
    }
  }

  &__additional-count {
    font: 300 ac(24px, 18px) / 108.33% var(--font-main);
    color: var(--red);
  }

  &__testimonial {
    position: relative;
    padding-left: ac(90px, 35px);
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: "\e90e";
      font-family: "icomoon" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: ac(48px, 20px);
      color: var(--red);
    }
  }

  &__testimonial-text {
    padding-bottom: ac(27px, 16px);
    font-size: ac(21px, 16px);
    font-weight: 500;
    line-height: 147.62%;
    color: var(--theme-black);

    p,
    a,
    li {
      font: inherit;
    }
  }

  &__testimonial-bottom {
    color: var(--red);
    p {
      font-size: ac(16px, 14px);
      font-weight: 500;
      line-height: 112.5%;
    }
  }
}

.popup__content {
  .select2-dropdown--below, .select2-dropdown {
    max-height: 141px !important;
  }

  .select2-results__options {
    max-height: 141px !important;
  }
}

.popup-block {
  background-color: var(--white);
  position: absolute;
  border-radius: 7px;
  margin: auto;
  width: 840px;
  min-height: 500px;
  max-height: 90%;
  overflow: auto;
  overflow-x: hidden;
  justify-content: normal;
  align-items: normal;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: var(--white);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--popup-dark-gray);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--white);
  }

  label {
    font-size: ac(16px, 15px);
    color: var(--popup-dark-gray);
  }
}

.cv-area {
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;

  input[type="file"] {
    display: none;
  }

  .cv-name {
    margin-bottom: 10px;

    span {
      color: var(--blue-green);
    }
  }
}

.custom-file-upload {
  border: 1px dashed var(--popup-light-gray);
  border-radius: 5px;
  padding: 19px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out all;

  span {
    transition: 0.3s ease-in-out all;
  }

  &:hover {
    border: 1px solid var(--popup-dark-blue);
    background-color: var(--popup-dark-blue);

    span {
      color: var(--white);
    }
  }
}

.popup-captcha {
  grid-column: 1 / -1;
}

@mixin media 551 {
  .popup {
    padding: 0 36px;

    .label {
      max-width: 225px;
    }

    .sign-up .buttons-wrap {
      flex-direction: column;
      align-items: flex-start;
      .reCaptcha-img {
        margin-bottom: 28px;
        max-width: 198px;
      }
    }

    .checkBox {
      span {
        flex: 1 1 auto;
        word-break: break-word;
      }
    }
  }
}

@mixin media 1024 {
  .popup {
    &__inner {
      grid-gap: 20px;
    }

    &__close {
      right: 15px;
      top: 15px;
      width: 17px;
      height: 17px;
    }
  }

  .popup-block {
    width: 95%;
    min-height: auto;
  }

  .custom-file-upload {
    padding: 15px;
  }
}

@mixin media 768 {
  .popup-captcha {
    transform: scale(0.8);
    transform-origin: 0;

    & > div {
      width: 100%;
      height: 110px;
    }

    .g-recaptcha {
      width: 100%;
      height: 110px;

      & > div {
        width: 100% !important;
        height: 100% !important;
      }
    }

    iframe {
      width: 304px;
      height: 100%;
    }
  }
}

@mixin media 550 {
  .popup {
    &__inner {
      grid-template-columns: 1fr;
    }
  }

  .popup-block {
    width: 95%;

    .close-popup {
      font-size: 20px;
      top: 20px;
      right: 15px;
    }
  }

  .popup-captcha {
    grid-column: 1;
    transform-origin: center;

    .g-recaptcha {
      & > div {
        & > div {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@mixin media 428 {
  .popup-captcha {
    transform: scale(0.7);
    overflow: hidden;

    & > div {
      height: 110px;
    }

    .g-recaptcha {
      height: 110px;
    }

    iframe {
      width: 300px;
    }
  }
}

@mixin media 360 {
  .popup__block {
    width: 100%;

    input,
    textarea,
    .upload-input {
      padding: 12px;
    }

    .reCaptcha-img {
      max-width: 200px;
    }

    &:not(.full-height) {
      max-height: 100%;
    }
  }
}
