/* ---- Contractors page ---- */
.technology-info-section {
  padding-top: ac(105px, 80px);
  padding-bottom: 20px;

  .technology-heading {
    flex: 0 0 auto;
    max-width: 456px;
    h3 {
      margin-bottom: 36px;
    }

    @mixin tab {
      max-width: 350px;
    }
  }
  @media only screen and (max-width: 700px) {
    .flex.justify-between {
      flex-direction: column; 
    }
  }

  .technology-text {
    p:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  .btn-wrap {
    flex-direction: column;
    align-items: flex-start;

    .btn:first-child {
      margin: 0 0 20px;
    }

    @mixin mob-lg {
      flex-direction: column;

      .btn:first-child {
        margin: 0 0 20px;
      }
    }
  }
}

/* Contractors Advice */
.contractor-advice {
  padding: 116px 0 ac(90px, 180px, 768, 1440);

  .advice-heading {
    max-width: 572px;
    margin: 0 auto 33px;
    text-align: center;

    h3 {
      color: var(--blue-green);
      margin-bottom: 25px;
    }

    .subtext {
      margin-bottom: 18px;
    }

    .btn {
      margin: 0 auto;
    }
  }

  .articles-slider {
    overflow: visible;
  }

  .decor-triangle {
    width: ac(295px, 152px);
    height: ac(320px, 164px);
  }

  .swiper-pagination {
    max-width: 768px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -1px;
  }
}

/* Contractor Sectors */
.contractor-sectors {
  padding-top: ac(135px, 100px);

  .slider-wrap {
    display: flex;
  }

  .sectors-heading {
    text-align: center;
    max-width: 765px;
    margin: 0 auto 50px;

    h3 {
      color: var(--red);
      margin-bottom: 26px;
    }

    .subtext {
      margin-bottom: 22px;
    }
  }

  .btn.red {
    margin: ac(77px, 160px, 768, 1440) auto 0;
  }

  .swiper-pagination {
    background: #e8e7e7;
  }
}

/* Presentation Section */
.presentation-section {
  display: flex;
  height: 100%;

  .container {
    display: flex;
    align-items: center;
    padding-bottom: 124px;
    padding-top: 87px;
    margin-left: 0;

    @mixin min-media 1920 {
      justify-content: flex-start;
    }

    @mixin tab {
      justify-content: center;
			margin-left: auto;
    }
  }

  @mixin tab {
    flex-direction: column;
    align-items: center;
    max-height: 100%;
  }
}

.presentation-image-wrap {
  max-width: ac(1024px, 680px, 1440, 1920);
  flex: 0 0 auto;
  margin-right: 48px;

	.presentation-img-swiper {
		clip-path: polygon(0 0, 100% 0, 80% 100%, 0 100%, 0 100%);
		height: 100%;
	}

  @mixin media 1440 {
    max-width: ac(680px, 530px, 1024, 1440);
  }

  @mixin tab {
    order: 2;
    margin: 0;
		height: ac(600px, 365px);
  }

  @mixin mob-xl {
    max-width: 100%;
    padding: 0 ac(35px, 15px, 320, 375);
  }
}

.presentation-image {
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.presentation-content {
  position: relative;
  max-width: ac(572px, 370px, 1024, 1440);

  .swiper-slide {
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.25s ease;
		padding-right: 20px;

    &-active {
      opacity: 1;
    }

    h3 {
      color: var(--red);
      margin-bottom: 16px;
    }

    .subtext {
      margin-bottom: 20px;

      p {
        font-weight: 500;
      }
    }

		[data-simplebar] {
			max-height: ac(155px, 164px);
			padding-right: 20px;
			width: calc(100% + 20px);
		}

    .inline-content-element {
      margin: 0;

      p {
        line-height: 160%;
      }

      strong,
      b {
        margin-bottom: 30px;
      }
    }

    .btn {
      margin-top: 20px;
    }
  }

  @mixin min-media 2400 {
    max-width: 1000px;
  }

  .pagination-main {
    bottom: -77px !important;
  }

  @mixin tab {
    max-width: 100%;
  }
}
