.inline-content-element {
  font: 300 ac(18px, 16px) / 171% var(--font-main) !important;

  pre {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }

  * {
    font-family: var(--font-main) !important;
    background: none !important;
  }

  h1,
  h2,
  h3 {
    text-transform: none;
  }
  h1 {
    font: 500 ac(32px, 22px) / 110% var(--font-main) !important;
    font-size: 20px !important;
    color: var(--theme-black);

    &:not(:last-child) {
      padding-bottom: ac(28px, 20px);
    }
  }

  h2 {
    font: 500 ac(26px, 20px) / 110% var(--font-main) !important;
  }

  h3 {
    font: 500 ac(21px, 18px) / 147.62% var(--font-main) !important;

    &:not(:last-child) {
      padding-bottom: ac(20px, 15px);
    }
  }

  h2,
  h3 {
    &:not(:last-child) {
      padding-bottom: 15px;
    }
  }

  p,
  li,
  td {
    font: 300 ac(18px, 16px) / 171% var(--font-main) !important;
  }

  span, a {
    display: inline;
  }

  a {
    font: inherit !important;
    font-weight: 400 !important;
    position: relative;
    transition: color 0.3s ease-in-out;
    text-transform: none;
    text-decoration: underline;
		word-break: break-all;

    &:hover {
      color: var(--red) !important;
      transition: color 0.3s ease-in-out;
    }
  }

  p,
  ul,
  ol,
  li {
    &:not(:last-child) {
      padding-bottom: ac(11px, 10px);
    }

    + h3 {
      padding-top: ac(22px, 15px);
    }
  }

  ul {
    list-style: none !important;
    padding-top: 20px;
    li {
      position: relative;
      padding-left: 20px !important;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 12px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: var(--theme-black);
      }
    }
  }

  ol {
    list-style-type: none !important;
    counter-reset: num;
    padding-top: 20px;

    li {
      padding-left: 20px !important;
      position: relative;

      &:before {
        position: absolute;
        color: var(--theme-black);
        top: 6px;
        left: 0;
        font-family: var(--font-main);
        font-size: inherit;
        font-weight: 500;
        line-height: 100%;
        content: counter(num) ".";
        counter-increment: num;
      }
    }
  }

  blockquote {
    font-style: italic;
    position: relative;
    padding-left: ac(25px, 14px);
    margin-bottom: 40px;

    *, p, span, a {
      font-style: italic !important;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: ac(4px, 2px);
      border-radius: 10px;
      background: var(--red);
    }
  }

  &.case-study-text-styles {
    h3 {
      font: 500 ac(24px, 18px) / 110% var(--font-main) !important;
    }

    p {
      line-height: 178% !important;
    }

    h2,
    h3 {
      color: var(--red);

      &:not(:last-child) {
        padding-bottom: 7px;
      }
    }
  }
}

.text-content, .text-heading {
  a {
    font: inherit !important;
    font-weight: 400 !important;
    position: relative;
    transition: color 0.3s ease-in-out;
    text-transform: none;

    &:not(.btn) {
      text-decoration: underline;
    }

    &[href^="tel:"], &[href^="mailto:"]  {
      display: inline-block;
    }

    &:hover {
      color: var(--red) !important;
      transition: color 0.3s ease-in-out;
    }
  }

  * {
    font-family: var(--font-main) !important;
  }

  ul {
    list-style: disc !important;

    li {
      position: relative;
      font-weight: 300 !important;

     /* &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: var(--theme-black);
      }*/
    }
  }

  ol {
    list-style-type: none !important;
    counter-reset: num;

    li {
      padding-left: 20px !important;
      position: relative;

      &:before {
        position: absolute;
        color: var(--theme-black);
        top: 6px;
        left: 0;
        font-family: var(--font-main);
        font-size: inherit;
        font-weight: 500;
        line-height: 100%;
        content: counter(num) ".";
        counter-increment: num;
      }
    }
  }

  blockquote {
    font-style: italic;
    position: relative;
    padding-left: ac(25px, 14px);
    margin-bottom: 40px;

    *, p, span, a {
      font-style: italic !important;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: ac(4px, 2px);
      border-radius: 10px;
      background: var(--red);
    }
  }
}

.share-links {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    text-transform: uppercase;
    color: var(--theme-black);
    line-height: 125%;
    font-size: ac(16px, 14px);
    margin-bottom: 10px;
    font-weight: 800;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__link {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--red);
    transition: background-color 0.3s ease;
    cursor: pointer;
    color: var(--white);
    font-size: 12px;
    line-height: 1;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      background: var(--blue-green);
    }

    &.icon-linkedin {
      font-size: 13px;
    }

    &.icon-facebook {
      font-size: 15px;
    }

    &.icon-mail {
      font-size: 11px;
    }
  }
}
