/* ---- Search Forms ---- */

.job-search {
  position: absolute;
  right: 0;
  bottom: ac(-32px, -152px, 901, 1440);

  background: var(--red-gradient);
  width: ac(1006px, 340px);
  padding: ac(35px, 30px) ac(140px, 38px, 901, 1440) ac(45px, 25px)
    ac(98px, 25px);
  z-index: 10;
  box-shadow: var(--main-shadow);

  h3, .h3 {
    color: var(--white);
  }
}

.job-search-form, .content-hub-form {
  display: flex;
  margin-top: ac(0px, 12px);
  position: relative;
  z-index: 15;
  justify-content: center;

  .keyword-search {
    height: ac(60px, 46px);
    flex: 0 0 auto;
    width: 100%;
    background-color: var(--light-grey);
    border: none;
  }

  .btn-ordinary {
    flex: 0 0 auto;
    width: ac(92px, 80px);
    margin-left: 6px;
  }
}

.job-search-form {
  .input-item {
    &:first-child {
      margin-right: 8px;

      input {
        clip-path: var(--right-corner);
        padding-right: 37px;
      }

      @mixin tab-md {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: ac(-40px, -25px, 1025, 1440);

      .keyword-search {
        padding: 0 10px 0 ac(80px, 55px, 900, 1440);
        clip-path: polygon(14% 0%, 100% 0, 100% 100%, 0 100%, 0 100%);
      }
    }
  }
}

.content-hub-form {
  .input-item {
    max-width: 480px;
  }
}

/* Footer Form */
.contact-us {
  box-shadow: none;
  padding: ac(115px, 60px) ac(115px, 36px) 70px ac(82px, 36px);

  @mixin min-media 1441 {
    width: 100%;
		padding-right: 82px;

    .contact-form {
      max-width: 710px;
      margin: 0 auto;

      form {
        max-width: 100%;
      }
    }
  }
}

.contact-form {
  color: var(--theme-black);
  .select2-container {
    width: 100% !important;
  }

  h2,
  h3, .h3 {
    font-weight: 500;
  }

  h2 {
    margin-bottom: ac(14px, 22px);
  }

  h3, .h3 {
    font-size: ac(32px, 22px);
    @mixin media 1400 {
      font-size: ac(28px, 18px);
    }

    @mixin media 1300 {
      font-size: 22px;
    }

    @mixin media 651 {
      font-size: 22px;
    }

    @mixin media 374 {
      font-size: 18px;
    }
  }
  form {
    width: 100%;
    max-width: 392px;
    display: grid;
    grid-row-gap: ac(15px, 7px);

    input,
    textarea {
      padding: ac(17px, 14px) 14px;
      border: 1px solid var(--black);
    }

    .select2-container--default .select2-selection--single {
      padding: ac(20px, 15px) 15px;

      .select2-selection__placeholder {
        color: var(--theme-black);
        @mixin max-line-length-one;
        max-width: 100%;
        letter-spacing: -0.5px;
      }
    }

    .select2-container {
      width: 392px;
    }
  }

  .btn {
    margin-left: auto;
  }
}

/* Responsive */

@mixin media 1151 {
  .contact-us {
    order: -1;
    width: 100%;

    .contact-form {
      form {
        max-width: 100%;
        .select2-container {
          width: 100% !important;
        }
      }
    }
  }
}

@mixin media 901 {
  .job-search {
    .job-search-form .btn-ordinary {
      margin: 8px auto 0 0;
    }
  }

  .job-search-form {
    display: grid;
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
    justify-items: center;

    .input-item {
      max-width: 100%;

      &:nth-child(2) {
        margin-left: 0;

				.keyword-search {
					clip-path: var(--right-corner);
					padding-left: 18px;
				}
      }
    }

    .btn-ordinary {
      height: 46px;
      margin: 8px 0 0 0;
    }
  }
}

@mixin media 769 {
  .main-filters {
    grid-template-columns: 1fr 1fr;
  }
}

@mixin media 551 {
  .main-filters {
    grid-template-columns: 1fr;
  }
}

@mixin media 360 {
  .hero-screen .job-search {
		left: 0;
  }
}
