/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: none;
  border-right: 0;
  border-bottom: 0;
  background: var(--white);

  color: var(--theme-black);
  font-family: var(--font-main);
  border-radius: 0;
  box-shadow: var(--main-shadow);

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
    text-transform: capitalize;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--theme-black);
  }

  #cc-nb-text {
    font-weight: 400;
  }
  #cc-nb-title {
    color: var(--red);
    font-family: var(--font-main);
    text-transform: uppercase;
  }
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    border-radius: 0;
    text-align: center;
    cursor: pointer;
    font-family: var(--font-main);
    font-size: 16px;
    line-height: normal;
    clip-path: var(--right-corner);
    overflow: hidden;
    z-index: 1;
    color: var(--white);
    border: 2px solid var(--red);
    border-right: 0;
    align-items: center;
    display: flex;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    padding: 13px 40px 13px 27px;
    position: relative;
    text-transform: uppercase !important;
    transition: all .3s ease-in-out;
    white-space: nowrap;
    width: 195px;
    background-color: var(--red);

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }

    &:after {
      bottom: 0;
      content: "";
      position: absolute;
      transition: all .4s ease;
      width: 2px;
      background-color: var(--red);
      right: 14px !important;
      transform: skewX(-28deg) !important;
      top: 0;
    }

    /*&:after {
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
      content: '\e900';
      color: var(--white);
      !*top: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0.8);
            r: 0;*!
      display: flex;
      margin-left: ac(11px, 8px);
      font-size: ac(11px, 10px);
      transition: transform 0.25s ease;
      display: none;
    }

    &:before {
      content: '';
      transition: all 0.5s ease;
      position: absolute;
      top: 0;
      bottom: 0;
      border-radius: 6px 30px 30px 6px;
      background: var(--cl-blue);
      display: flex;
      justify-content: center;
      align-items: center;
      width: ac(43px, 40px);
      left: 0;
      width: 2px;
      height: 100%;
      background: var(--blue-green);
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      transition: all .3s ease;
    }*/

    &:hover {
      background-color: transparent;
      color: var(--red);

      &:after {
        transform: skewX(-29deg) !important;
      }

      /*&:after {
        transform: translateX(6px);
      }*/

      &:before {
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }

  .cc-nb-changep {
    width: 260px;
    clip-path: var(--right-corner-xxl);
    letter-spacing: -0.5px;
    background-color: var(--blue-green);
    border-color: var(--blue-green);

    &:after {
      background-color: var(--blue-green);
      right: 15px !important;
      transform: skewX(-30deg) translateX(10%) !important;
    }

    &:hover {
      background-color: transparent;
      color: var(--blue-green);

      &:after {
        transform: skewX(-30deg) translateX(10%) !important;
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    grid-column-gap: ac(10px, 5px);
    grid-row-gap: 0;
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
  color: var(--red);
  transition: all 0.3s ease;
  text-transform: none;
  font: inherit;

  &:hover {
    color: var(--theme-black);
    text-decoration-color: var(--theme-black);
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: none;
    border-radius: 0;
    overflow: hidden;
    box-shadow: var(--main-shadow);
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--white) !important;
    border-top-color: var(--theme-black) !important;
    border-bottom-color: var(--theme-black) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-body-tabs {
    background-color: var(--light-grey);
  }

  .cc-cp-body-tabs-item {
    background: var(--light-grey);
  }

  .cc-cp-body-tabs-item-link {
    color: var(--theme-black);

    &:focus {
      box-shadow: none;
    }
  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--red) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--white);
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--theme-black);
      padding: 5px;
      background: var(--light-grey);
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--red);
    transition: all 0.3s ease;

    &:hover {
      color: var(--black);
      transform: scale(1.1);
    }

    &:focus {
      border: 0;
    }
  }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--red);
      text-decoration: underline;
      transition: all 0.3s ease;

      &:hover {
        color: var(--theme-black);
        text-decoration-color: var(--theme-black);
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    border-radius: 0;
    text-align: center;
    cursor: pointer;
    font-family: var(--font-main);
    font-size: 16px;
    line-height: normal;
    overflow: hidden;
    z-index: 1;
    color: var(--white);
    border: 2px solid var(--red);
    border-right: 0;
    align-items: center;
    display: flex;
    font-weight: 600;
    justify-content: center;
    padding: 13px 40px 13px 27px;
    position: relative;
    text-transform: uppercase !important;
    transition: all .3s ease-in-out;
    white-space: nowrap;
    background-color: var(--red);
    width: 250px;
    clip-path: var(--right-corner-xxl);
    letter-spacing: -0.5px;
    height: 50px;

    &:focus {
      box-shadow: none !important;
      border: none !important;
    }

    &:after {
      bottom: 0;
      content: "";
      position: absolute;
      transition: all .4s ease;
      width: 2px;
      background-color: var(--red);
      right: 14px !important;
      transform: skewX(-30deg) translateX(15%) !important;
      top: 0;
    }

    /*&:after {
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
      content: '\e900';
      color: var(--white);
      !*top: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0.8);
            r: 0;*!
      display: flex;
      margin-left: ac(11px, 8px);
      font-size: ac(11px, 10px);
      transition: transform 0.25s ease;
      display: none;
    }

    &:before {
      content: '';
      transition: all 0.5s ease;
      position: absolute;
      top: 0;
      bottom: 0;
      border-radius: 6px 30px 30px 6px;
      background: var(--cl-blue);
      display: flex;
      justify-content: center;
      align-items: center;
      width: ac(43px, 40px);
      left: 0;
      width: 2px;
      height: 100%;
      background: var(--blue-green);
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      transition: all .3s ease;
    }*/

    &:hover {
      background-color: transparent;
      color: var(--red);

      &:after {
        transform: skewX(-30deg) translateX(10%) !important;
      }

      /*&:after {
        transform: translateX(6px);
      }*/

      &:before {
        left: 100%;
        transform: translateX(-100%);
      }
    }

    @mixin media 530 {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
  }
}

.termsfeed-com---palette-dark {

  .cc-cp-body-content-entry-text {
    color: var(--theme-black);
  }

  .cc-cp-body-content-entry-title {
    color: var(--theme-black);
  }

  .cc-pc-head-title-text {
    color: var(--red);
  }

  .cc-pc-head-title-headline {
    color: var(--theme-black);
  }

  .cc-cp-foot-byline {
    color: var(--theme-black);
  }
}

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox + label {
  color: var(--theme-black);

  &:after, &:before {
    border-color: var(--theme-black);
  }
}

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox:checked + label::before {
  background: var(--red);
}

/*
Styles for mobile adaptation
*/
@mixin media 651 {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }
}
