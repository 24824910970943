/* ---- Job Board Page---- */
.job-board-page {
  &.interior-page {
    padding-top: 84px;
  }

  .offer-text {
    margin: 0 0 52px;
  }
}

.jobs-board-section {
  padding: ac(105px, 80px) 0 ac(140px, 80px);
  background: url("../images/decor-items/job-board-decor.svg") no-repeat 35% 10% /
    100%;

	&.hide-jobs {
		.filters-sidebar {
			max-width: 100%;
			text-align: center;

			h3 {
				display: block;
			}
		}

		.jobs-filters, .jobs-board {
			display: none;
		}
	}
}

/* Filters */
.filters-sidebar {
  flex: 0 0 auto;
  max-width: 315px;
  width: 100%;
  background-color: rgb(235 237 238 / 80%);

  height: fit-content;
  top: 125px;
  position: -webkit-sticky;
  position: sticky;

	h3 {
		display: none;
	}

  @mixin media 991 {
    max-width: 100%;
  }
}

.jobs-filters {
  width: 100%;
	max-width: 265px;

  .filters-children {
    padding: 0 0 10px 22px;
  }

	@mixin media 1025 {
		max-width: 100%;
	}
}

.filter-items-list {
  display: flex;
  flex-direction: column;
}

.filter-item {
  &.is-open {
    i {
      transform: rotate(180deg);
    }

    form {
      margin-top: 10px;
      padding: 10px 20px;
    }
  }

  &:not(:last-child) {
    margin-bottom: ac(50px, 25px);
  }

  .filters {
    .checkBox {
      span {
        font-size: 16px;
        padding-left: 25px;

        &:before {
          border: 2px solid var(--red);
          size: 14px;
          top: 4px;
          bottom: auto;
        }
      }

      &:hover {
        span {
          color: var(--red);
        }
      }
    }
  }

  form {
    margin-top: 0;
    padding: 0 20px;
    transition: 0.2s linear;
  }
}


.filters-parent {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:hover {
    i {
      color: var(--red);
    }
  }

  /* .filters-trigger {
    font-size: 12px;
    margin: 0 8px 0 -20px;
    transition: transform 0.2s ease;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      transition: all 0.2s ease;
      transform: rotate(0);
    }
  } */
}

.filter-dropdown {
  background: var(--white);
  clip-path: var(--right-corner);

  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;

  .placeholder {
    font-size: 16px;
    font-weight: 600;
    margin-right: 18px;
    text-transform: uppercase;
  }

  i {
    font-size: 12px;
    transition: 0.2s ease;
  }
}

/* Board */
.jobs-board {
  max-width: 768px;
  width: 100%;
  padding-top: 25px;
  margin-left: 40px;

  @mixin media 991 {
    margin: 40px 0 0 0;
  }

  .jobs-result {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 30px;
  }
}

.job-board-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 37px;

  &_mobile {
    display: none;
  }

  .btn {
    display: flex;
    align-items: center;

    .icon-bell {
      position: absolute;
      left: 35px;
      transition: 0.3s ease;
      animation: ring 4s 0.7s ease-in-out infinite;
    }

    span {
      padding-left: 63px;
    }

    &:hover {
      .icon-bell {
        color: var(--red);
      }
    }
  }

  @mixin media 1150 {
    flex-direction: column;
  }
}

.job-board-heading {
  max-width: 376px;
  margin-right: 20px;

  h3,
  .jobs-amount {
    line-height: 106%;
  }

  h3 {
    color: var(--blue-green);
    margin-right: 20px;
  }

  .tip {
    margin-top: 18px;
    line-height: 120%;
  }

  .jobs-amount {
    font-size: 21px;
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: 1;

    #count-jobs {
      line-height: 1;
      margin-right: 6px;
      display: inline-block;
    }
  }

  @mixin media 1150 {
    margin: 0 0 20px;
    max-width: 100%;
  }
}


.jobs-result {
  .job-item {
    justify-content: space-between;
    padding-right: ac(58px, 15px);

    .desc {
      font-size: ac(18px, 14.5px);
      font-weight: 300;
      line-height: 155%;

      @media only screen and (min-width: 1919px) {
        font-size: 20px;
      }
    }

    &:not(:last-child) {
      /* margin-bottom: 30px; */
    }
  }
}

/* Pagination */
.jobs-pagination {
  margin-top: 90px;
}

.pagination-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-item {
  text-transform: uppercase;

  &.pagination-arrow {
    align-self: center;
  }

  a {
    position: relative;
    padding-bottom: 4px;
  }

  &:not(.disabled):hover {
    span,
    i {
      color: var(--red);
    }
  }

  span {
    font-size: 16px;
    font-weight: 500;
    width: fit-content;
    margin: 0 auto;
    transition: 0.3s ease;
  }

  i {
    font-size: 9px;
    transition: 0.3s ease;
  }

  &.disabled {
    opacity: 0.4;

    a {
      pointer-events: none;
    }
  }

  @mixin mob-lg {


        .pagination-num {
              width: 25px;
          margin-left: 0 !important;
          margin-right: 0 !important;

          &.pagination-dots {
            width: 15px;
          }

          span {
            font-size: 14px;
          }
        }

      .pagination-btn-prev {
        margin-right: 10px;
        padding-bottom: 0;
      }

      .pagination-btn-next {
        margin-left: 10px;
        padding-bottom: 0;
      }
  }
}

.pagination-btn-prev, .pagination-btn-next {
  display: flex;
  align-items: center;
}

.pagination-btn-prev {
  margin-right: 27px;

  i {
    transform: translateX(-10px) rotate(90deg);
  }

  &:not(.disabled a):hover {
    i {
      left: -17px;
    }
  }
}

.pagination-btn-next {
  margin-left: 27px;

  i {
    transform: translateX(10px) rotate(-90deg);
  }

  &:hover {
    i {
      right: -17px;
    }
  }
}

.pagination-num {
  width: 31px;
  position: relative;

  &:not(.pagination-dots) {
    margin-right: 8px;
  }

  &.active {
    border-bottom: 2px solid var(--red);

    span {
      color: var(--red);
    }
  }
}

.pagination-dots {
  margin: 0 10px 0 5px;
}

/* ---- Job Details page ---- */
.job-details-page {
  padding: 50px 0 42px;

  .container {
    padding-top: 88px;
  }

  h3 {
    color: var(--white);
    margin-bottom: 11px;
  }

  .job-sector {
    display: flex;

    .sector-name {
      color: var(--white);
      line-height: 110%;
      text-transform: none;
    }

    .sector-icon {
      size: 25px;
      margin-right: 12px;
    }
  }
}

.job-details-section {
  padding-top: 60px;
}

.job-info {
  max-width: 670px;

  .job-details {
    margin-bottom: 44px;

    .details-item {
      display: flex;
      flex-wrap: wrap;

      label,
      p {
        line-height: 110%;
      }

      label {
        display: block;
        font-size: 21px;
        font-weight: 500;
        margin-right: 10px;
      }

      p {
        font-size: 21px;
      }

      &:not(:last-child) {
        margin-bottom: 13px;
      }

      @mixin mob-lg {
        flex-direction: column;

        label {
          margin-bottom: 5px;
        }
      }
    }
  }

  @mixin media 1151 {
    order: 2;
    max-width: 100%;
  }
}

.job-description {
  margin: 45px 0 35px;

  a {
    text-transform: none;
  }
}

.consultant-box {
  margin-left: 50px;
  margin-top: 0;

  .consultant {
    position: sticky;
    top: 125px;
  }

  @mixin media 1151 {
    margin: 0 0 40px 0;
  }
}

.consultant-avatar {
  size: ac(376px, 280px, 768, 1440);
  margin-bottom: 16px;
  flex: 0 0 auto;

  @mixin media 1151 {
    margin: 0 25px 0 0;
  }

  @mixin mob-xl {
    margin: 0 0 25px;
  }
}

.consultant-name {
  margin-bottom: 16px;
}

.consultant-contacts {
  margin-bottom: 36px;

  .contact-link {
    .contact-icon {
      flex: 0 0 auto;
      width: 30px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--white);
      background: var(--blue-green);
      margin-right: 12px;
      clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
      font-size: 14px;
      transition: 0.3s ease;
    }

    p {
      font-size: ac(21px, 16px, 650, 1150);
      font-weight: 500;
      text-transform: none;
      word-break: break-word;
    }

    a {
      display: flex;
      align-items: center;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &:hover {
      .contact-icon {
        background: var(--red);
      }
    }
  }
}


@mixin media 1024 {
  .filters-sidebar {
    max-width: 100%;
    position: static;
  }
  .jobs-board {
    margin: 40px 0 0;
  }
}