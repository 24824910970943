@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600&display=swap");

@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?lvyd4b');
	src:  url('../fonts/icomoon.eot?lvyd4b#iefix') format('embedded-opentype'),
	url('../fonts/icomoon.woff2?lvyd4b') format('woff2'),
	url('../fonts/icomoon.ttf?lvyd4b') format('truetype'),
	url('../fonts/icomoon.woff?lvyd4b') format('woff'),
	url('../fonts/icomoon.svg?lvyd4b#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
	content: "\e90d";
}
.icon-twitter:before {
	content: "\e903";
}
.icon-youtube:before {
	content: "\e913";
}
.icon-quotes:before {
	content: "\e90e";
}
.icon-pencil:before {
	content: "\e914";
}
.icon-close:before {
	content: "\e912";
}
.icon-phone:before {
	content: "\e911";
}
.icon-mail-2:before {
	content: "\e910";
}
.icon-bell:before {
	content: "\e90f";
}
.icon-avatar:before {
	content: "\e90a";
}
.icon-hr:before {
	content: "\e90b";
}
.icon-manufacturing:before {
	content: "\e90c";
}
.icon-facebook:before {
	content: "\e900";
}
.icon-instagram:before {
	content: "\e901";
}
.icon-linkedin:before {
	content: "\e902";
}
.icon-arrow-next:before {
	content: "\e904";
}
.icon-tools:before {
	content: "\e905";
}

.icon-location:before {
	content: "\e907";
}
.icon-search:before {
	content: "\e908";
}
.icon-arrow-down:before {
	content: "\e909";
}

.icon-pound {
  width: 12px;
  height: 12px;
  min-width: 12px;

  background: url(../images/icons/circle.svg) no-repeat center / contain;
}

:root {
  --theme-black: #1f1b1b;
  --dark-grey: #5b5b5b;
  --light-grey: #ebedee;
  --light-grey-2: #e8e7e7;
  --red: #e6272c;
  --blue-green: #008199;
  --red-gradient: linear-gradient(
    0deg,
    rgba(226, 38, 44, 1) 0%,
    rgba(255, 90, 94, 1) 100%
  );
  --red-gradient-2: linear-gradient(0deg, rgba(255,90,94,1) 0%, rgba(226,38,44,1) 100%);
  --black: #000;
  --white: #fff;
  --shadow-color: rgba(0, 0, 0, .24);

  /* Font Family */
  --font-main: "Outfit", sans-serif;

  /* Box Shadow */
  --main-shadow: 5px 10px 15px var(--shadow-color);
  --second-shadow: 5px 5px 6px var(--shadow-color);
  --third-shadow: 5px 5px 10px var(--shadow-color);
  --shadow-filter: drop-shadow(5px 10px 15px var(--shadow-color));
  --shadow-button: drop-shadow(5px 0 15px var(--shadow-color));

  /* Clip Path */
  --right-corner: polygon(0 0%, 100% 0, 86% 100%, 0 100%, 0 100%);
  --right-corner-xxl: polygon(0 0%, 100% 0, 89% 100%, 0 100%, 0 100%);
  --right-top: polygon(0 0%, 86% 0, 100% 100%, 0 100%, 0 100%);

  /* Popup colors */
  --popup-dark-blue: #0e1127;
  --popup-dark-gray: #343434;
  --popup-light-gray: #dce1e4;

  --default-border: 2px solid var(--blue-green);

  --left-position-span: 0;
  --width-span: 0;
  --span-transition: opacity 0.5s ease, visibility 0s 0.5s;
  --span-transition-mouse-enter: opacity 0.5s ease, visibility 0s 0.5s;
  --span-transition-mouse-leave: opacity 0.5s ease, visibility 0s 0s;
  --span-transition-mouse-moving: 0.5s cubic-bezier(0.75, 0, 0, 1);
}

/* classes with animation */

[data-aos="show-left"] {
  right: -100%;

  &.aos-animate {
    right: ac(-82px, -60px);
  }
}

.animated {
  opacity: 0;
}

.slideLeft {
  opacity: 0;
  animation: slideLeft 1.5s forwards;
}

.slideRight {
  opacity: 0;
  animation: slideRight 1.5s forwards;
}

.slideDown {
  animation: slideDown 0.5s forwards;
}

.fadeIn {
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}

.pulse {
  animation: pulse 0.3s forwards;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
