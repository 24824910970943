/* Infographic Wheel */
.sections-wrap {
    &:before {
        transform: translate(58%,-10%) rotate(28deg);
    }
}

.infographic-wheel {
    height: 100vw;
    transition: 0.5s ease;

		display: flex;
		align-items: center;
		justify-content: center;

    .wheel-container {
        height: 100%;
        width: 100vw;
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        &.disabled {
            position: initial !important;
            margin: initial !important;
            inset: initial !important;
        }

				@mixin media 801 {
					transform: scale(0.9);
				}

				@mixin media 701 {
					transform: scale(0.8);
				}

				@mixin media 601 {
					transform: scale(0.6);
				}

				@mixin media 501 {
					transform: scale(0.5);
				}
    }

    &.focused {
        height: 100vw;
    }

    @mixin media 1023 {
        padding: 60px 0;
        display: block;

        &.focused {
            height: auto;
        }
    }

		@mixin media 901 {
			height: 120vw;
		}

		@mixin media 451 {
			height: 145vw;
		}
}

.wheel-box {
    display: flex;
    align-items: center;
    justify-content: center;
    size: 20vw;
    position: relative;
    margin: 0 auto;

		min-width: 198px;
		min-height: 198px;
}

.wheel-sectors {
    position: absolute;
    size: 20vw;
    z-index: 5;

		min-width: 198px;
		min-height: 198px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

	@mixin media 551 {
		min-width: 185px;
		min-height: 185px;
	}
}

.wheel-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 20;

    size: 14vw;
    padding: 28px 20px 20px;
    border-radius: 50%;
    border: ac(20px, 10px, 1024, 1440) solid var(--blue-green);

		min-width: 140px;
		min-height: 140px;

    .wheel-title {
        color: var(--red);
        text-align: center;
        font-size: ac(17px, 12px, 1024, 1440);
        margin-bottom: ac(12px, 8px, 1024, 1440);

        @mixin min-media 1920 {
            font-size: 26px;
        }

        @mixin min-media 2536 {
            font-size: 30px;
        }
    }

    .swiper-btn-prev,
    .swiper-btn-next {
        size: ac(27px, 20px, 1024, 1440);
        background: var(--theme-black);
        color: var(--white);
        font-size: 12px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.25s ease;

        cursor: pointer;

        &:hover:not(.swiper-button-disabled) {
            background: var(--blue);
        }

        &.swiper-button-disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        @mixin min-media 2536 {
            font-size: 18px;
            size: 40px;
        }
    }

    .swiper-btn-prev {
        transform: scale(-1);
        clip-path: polygon(12% 0,100% 0,100% 100%,0 100%,0 100%);
    }

    .swiper-btn-next {
        clip-path: polygon(12% 0,100% 0,100% 100%,0 100%,0 100%);
    }
}

.wheel-swiper {
    position: absolute;
    width: 100%;
    height: 100%;

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            pointer-events: none;
        }

        .slide-box {
            position: relative;
            border-radius: 50%;
            transition: 0.3s ease;
        }
    }
}

.icons-list {
    height: 100%;
    border-radius: 50%;
    text-align: center;
}

.level-1 {
    margin-left: -4px;
    size: 33vw;
    background: url("../images/wheel/arrow-circle-bg.svg") no-repeat center / contain;
    transition: 0.3s ease;

    min-width: 327px;
    min-height: 327px;

    .icons-list__item {
        size: 3.5vw;

        p {
            font-size: ac(11px, 8px, 1024, 1440);
            

            @mixin min-media 1920 {
                font-size: 14px;
            }

            @mixin min-media 2536 {
                font-size: 16px;
            }
        }

        /* Positioning */
        &:first-child {
            left: 51%;
            top: 6%;

            p {
                bottom: 40%;
                left: 110%;
            }
        }

        &:nth-child(2) {
            right: 10%;
            top: 26%;

            p {
                top: 120%;
                left: 28%;
            }
        }

        &:nth-child(3) {
            right: 8%;
            bottom: 30%;

            p {
                top: 120%;
								left: ac(-32px, -41px, 414, 1440);
            }
        }

        &:nth-child(4) {
            bottom: 8%;
            right: 31%;

            p {
                bottom: -10%;
                right: 100%;
            }
        }

        &:nth-child(5) {
            left: 23%;
            bottom: 11%;

            p {
                bottom: 100%;
                right: 90%;
            }
        }

        &:nth-child(6) {
            left: 5%;
            top: 48%;

            p {
                bottom: 120%;
                left: 30%;
            }
        }

        &:nth-child(7) {
            left: 19%;
            top: 16%;

            p {
                bottom: 110%;
                left: 80%;
            }
        }

        @mixin media 801 {
            &:first-child {
                p {
									bottom: ac(8px, 1px, 320, 801);
									left: calc(100% + 6px);
                }
            }

						&:nth-child(2) {
                p {
                    left: ac(0px, -20px, 320, 801);
                }
            }

						&:nth-child(4) {
                p {
									right: ac(28px, 22px, 415, 801);
                }
            }

            &:nth-child(5) {
                p {
                    right: ac(20px, -8px, 375, 801);
                }
            }
        }
    }

		@mixin media 501 {
			min-width: 300px;
			min-height: 300px;
		}
}

.level-2 {
    size: 41.5vw;
    border: 12px solid var(--red);
    min-width: 410px;
    min-height: 410px;

    .icons-list__item {
        top: -8px;
        transform: translateY(-50%);
        transform-origin: top;
        size: 41px;

        p {
            color: var(--theme-black);
            position: absolute;
            text-align: left;
            width: max-content;

            @mixin min-media 2536 {
                font-size: 21px;
            }
        }
    }

    @mixin media 701 {
        min-width: 420px;
        min-height: 420px;
    }
    
    @mixin media 390 {
        transform: scale(0.82);
    }
}

.level-3 {
    size: 72vw;

    @mixin min-media 1920 {
        size: 80vw;
    }

    @mixin min-media 2536 {
			.slide-box__bg {
				transform: rotate(-1deg) !important;
			}
    }

    @mixin media 1441 {
        size: ac(1188px, 800px, 1024, 1440);
    }

		@mixin media 801 {
			transform: scale(1.1);
		}

		@mixin media 701 {
			transform: scale(1.2);
		}

		@mixin media 601 {
			transform: scale(1.5);
		}

		@mixin media 501 {
			transform: scale(1.65);
		}

		@mixin media 451 {
			transform: scale(1.95);
		}

    .slide-box__titles {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

			display: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .slide-box__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: rotate(-1.5deg);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}

.icon-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: center;
    transform: rotate(0deg);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    transition: ease-in-out 0.5s;

		&:nth-child(4) {
			p {
				bottom: calc(100% + 8px) !important;
				left: calc(100% + -10px) !important;
			}
		}

		&:nth-last-child(2) {
			p {
				bottom: calc(100% - 20px) !important;
				right: calc(100% + 10px) !important;
			}
		}

		&:nth-child(7) {
			p {
				left: calc(100% + 8px) !important;
				top: calc(100% - 20px) !important;
			}
		}

		&:nth-child(8) {
			p {
				left: calc(100% + 20px) !important;
				top: calc(100% + -30px) !important;

				@mixin min-media 1920 {
					left: calc(100% + 30px)!important;
					top: calc(100% - 45px)!important;
				}
			}
		}

		&:nth-child(11) {
			p {
				right: calc(100% - 20px) !important;
				top: calc(100% + 10px) !important;
			}
		}

		&:nth-child(13) {
			p {
				top: calc(100% + 10px)!important;
				right: calc(100% - 10px)!important;
			}
		}
}

.icons-list__item {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease 0.5s;

    background: url("../images/wheel/icon-bg-circle.png") no-repeat center / contain;

    img {
        width: 50%;
        height: 50%;
        object-fit: contain;
        transition: ease 0.3s;
    }

    p {
        position: absolute;
        text-align: center;
        font-size: ac(12px, 10px, 1024, 1440);
        font-family: var(--font-main);
        line-height: 125%;
        color: var(--white);
        transition: ease 0.5s;
				font-weight: 600;

        @mixin min-media 1920 {
            font-size: 16px;
        }
    }

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

/* Infographic Mobile Wheel */
.wheel-mobile {
    display: none;

    p, .services-item {
        text-transform: uppercase;
        font-size: ac(13px, 10px, 375, 1024);
        font-weight: 600;
        line-height: 140%;
    }

    .sector-item {
        position: relative;
        display: flex;
        align-items: center;

        p {
            margin-left: 5px;
        }

        .sector-item__icon {
            display: flex;
            justify-content: center;
            align-items: center;

            size: 42px;
            background: url("../images/wheel/icon-bg-circle.png") no-repeat center / contain;

            img {
                width: 45%;
                height: 45%;
                object-fit: contain;
            }
        }
    }

    .services-item {
        display: flex;
        align-items: center;

        &:before {
            content: '';
            display: block;
            size: 27px;
            margin-right: 12px;
            background: url("../images/wheel/icon-bg-circle.png") no-repeat center / contain;
        }
    }

    --swiper-pagination-color: var(--black);
}

.wheel-categories {
    display: flex;
    width: 330px;
    flex-wrap: wrap;
    margin: 0 auto;

    .wheel-categories__item {
        color: var(--white);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;

        height: 40px;
        width: 108px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--blue-green);

        &:first-child, &:nth-child(4) {
            clip-path: var(--right-corner);
        }

        &:nth-child(2) {
            margin: 0 -10px;
            clip-path: polygon(14% 0,100% 0,86% 100%,0 100%,0 100%);
        }

        &:nth-child(5),
        &:nth-child(3) {
            clip-path: polygon(14% 0,100% 0,100% 100%,0 100%,0 100%);
        }

        &:nth-child(4), &:nth-child(5) {
            width: 157px;
            margin-top: 5px;
        }

        &:nth-child(4) {
            clip-path: polygon(0 0,100% 0,90% 100%,0 100%,0 100%);
            padding-right: 15px;
        }

        &:nth-child(5) {
            clip-path: polygon(10% 0,100% 0,100% 100%,0 100%,0 100%);
            margin-left: -10px;
            padding-left: 12px;
        }

        @mixin media 360 {
            width: 102px;
            &:nth-child(4), &:nth-child(5) {
                width: 148px;
            }
        }
    }
}

.wheel-mob-swiper {
    .swiper-slide {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: ac(30px, 15px, 375, 1024);
        grid-column-gap: ac(30px, 10px, 375, 1024);

        @mixin tab-sm {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}