/* ---- About Us page ---- */

.note-section {
  padding: ac(105px, 80px) 0;
  z-index: 10;

  .info-box {
    text-align: center;
    max-width: 964px;
    margin: 0 auto;

    .subtext {
      margin-bottom: 10px;
    }

    .btn-wrap {
      margin-top: 30px;
      justify-content: center;

      @mixin media 551 {
        flex-direction: column;

        .shadow-btn:first-child {
          margin: 0 0 10px 0;
        }
      }
    }
  }

  .note-text {
    font-size: ac(21px, 18px);
  }

  .inline-content-element {
    max-width: 666px;

    @mixin tab {
      max-width: 100%;
    }
  }
}

/* Sectors section */
.sectors-section {
  padding: ac(140px, 80px) 0 196px;

  .slider-wrap {
    margin-top: 70px;
    display: flex;
  }

  .swiper-pagination {
    bottom: -51px;
  }
}

.sectors-info {
  max-width: 670px;

  p {
    line-height: 170%;
  }
}

.sectors-heading {
  flex: 0 0 auto;
  margin-right: 40px;

  h3 {
    margin-bottom: 28px;
  }
}

/* Cards Section */
.cards-section {
  padding: ac(102px, 80px) 0 ac(120px, 80px);
  background: var(--blue-green);
}

.card-block {
  max-width: 572px;
  padding: ac(55px, 25px) ac(80px, 25px) ac(34px, 25px);
  background: var(--white);
  text-align: center;
  margin: 8px;

  display: flex;
  flex-direction: column;
  flex: 0 0 calc(50% - 16px);

  h3 {
    color: var(--dark-grey);
    margin-bottom: 20px;
  }

  p {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: ac(21px, 18px) !important;
  }

  .btn {
    margin: auto auto 0;
  }

  @mixin desk-lg {
    padding: 50px 40px;
  }

  @mixin media 991 {
    flex-basis: 100%;
    max-width: 100%;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @mixin tab-sm {
    flex: 1;
    max-width: 100%;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 700px) {
  .sectors-section {
    .flex.justify-between {
      flex-direction: column;
    }
    .sectors-heading {
      margin-bottom: 25px;
    }
  }
}