/* ---- Clients ---- */
.clients-section {
  h2 {
    margin-bottom: 20px;

    @mixin mob-xl {
      text-align: center;
    }
  }

  @mixin mob-xl {
    padding: 40px 0;
  }

}

.clients-list {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 11px;
  align-items: center;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 25px;
  margin-top: 25px;

  @mixin tab-sm {
    grid-template-columns: repeat(3, 1fr);
  }

  @mixin mob-xl {
    display: none;
  }
}

.clients-item {
  box-shadow: var(--main-shadow);
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  margin-bottom: 16px;

  max-width: 278px;
  width: 100%;

  &:not(.client-preview-slider .clients-item):hover {
    box-shadow: 0 0 0 var(--shadow-color);

    img {
      transform: scale(0.8);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.3s ease-in-out;
  }

  &:not(.client-preview-slider .clients-item) {
    mask-image: none !important;
    @mixin aspect-ratio 278, 120;

    img {
      left: auto;
      top: auto;
      width: 90%;
      height: 90%;
    }
  }
}

.clients-slider {
  display: none;
  overflow: visible;
  margin-top: 25px;

  .clients-item {
    max-width: 100%;
  }

  @mixin mob-xl {
    display: block;
  }
}
