/* Styles only Page Builder */

.white-section, .grey-section {
    box-shadow: none;

    &.top-shadow {
        box-shadow: 0 -5px 5px 1px rgba(0,0,0,.05);
    }
}

.white-section {
    background: var(--white);

    + .grey-section {
        &:not(.top-shadow) {
            box-shadow: inset 0 25px 10px -20px var(--shadow-color);
        }
    }
}

.grey-section {
    background: transparent;

    + .white-section {
        &:not(.top-shadow) {
            box-shadow: inset 0 25px 10px -20px var(--shadow-color);
        }
    }
}

.interior-page {
    min-height: ac(615px, 500px);
    padding-top: ac(105px, 80px);

    display: flex;
    align-items: center;
    justify-content: center;

    &.page-builder-page {
        padding-top: 96px;
        padding-bottom: 30px;

        .offer {
            max-width: 950px;

            .offer-text {
                &, p {
                    max-width: 670px;
                    text-align: center;
                    margin: 0 auto;
                    line-height: 140%;
                }
            }

            h1 {
                line-height: 120.93%;
                margin-bottom: 20px;
            }
        }
    }
}

/* Styles for text-sections */
.text-section {
    li {
        width: fit-content;
    }

    .text-heading {
        &.not-text-transform {
            h1, h2, h3, h4 {
                text-transform: none;
            }
        }
    }

    &.text-only {
        .text-content {
            margin-top: ac(-2px, 0px);
        }
    }

    &.text-and-buttons {

        .btn-wrap {
            /*padding-top: ac(46px, 30px);*/
            .shadow-btn {
                &:only-child {
                    margin-top: ac(14px, 0px);
                }
            }
        }
    }

    &.narrow-centered-text,
    &.wide-centered-text {
        .text-section {
            &__wrap {
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }
        }

        .text-heading,
        .text-content {
            width: 100%;
            text-align: center;
        }

        .text-heading + .text-content {
            padding-top: 12px;
        }

        .btn-wrap {
            padding-top: ac(36px, 30px);
            flex-direction: row;
            justify-content: center;

            &:last-child {
                margin-bottom: -20px;
            }

            @mixin media 600 {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &.narrow-centered-text {
        .text-section {
            &__wrap {
                max-width: 1040px;
            }
        }

        .text-heading {
            margin: 0 auto;

            h3 + p {
                max-width: 500px;
                margin: 0 auto; 
            }
        }

        li {
					width: 100%;
					margin-left: 20px;

					@mixin media 551 {
						text-align: left;
						max-width: calc(100% - 20px);
					}
        }
    }

    &.text-section-3 {
        ul {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: ac(80px, 40px, 651);
						margin: 0 auto;
						text-align: left;

            @mixin media 769 {
                grid-template-columns: repeat(2, 1fr);
            }

            @mixin media 551 {
                grid-template-columns: 1fr;
            }
        }
    }

    &.wide-centered-text {
        .text-heading {
            margin-right: 0;
        }

        .text-section {
            &__wrap {
                max-width: ac(964px, 700px);
            }
        }

        .text-content {
            p, li {
                font-size: ac(21px, 16px);
                font-weight: 300;
                line-height: 147.62%;
                color: var(--theme-black);
            }
        }
    }

    &.text-and-benefits {

        .text-section__wrap {
            margin-bottom: 40px;
            max-width: 645px;
            text-align: center;

            h3 {
                margin-bottom: 16px;
            }
        }

        .text-and-benefits__wrap {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .text-section {
            &__wrap {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                @mixin media 900 {
                   width: 100%;
                }
            }
        }

        .text-heading {
            width: 100%;
            margin-right: 0;

            h3 + p {
                padding-top: 8px;
            }
        }

        .text-content {
            padding-top: 5px;
            width: 100%;
        }

        .benefits-content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content:  center;
            width: 95%;

            @mixin media 900 {
                width: 100%;
            }

            @mixin media 551 {
                flex-direction: column;
                align-items: center;
            }

            &__item {
                position: relative;
                z-index: 1;
                text-align: center;
                margin: 8px;
								background: var(--red);
								padding: 1px;
								clip-path: polygon(0 1%, 100% 0%, 86% 100%, 0% 100%);

								@mixin media 551 {
									margin-right: 0;
								}
            
                span {
                    color: var(--theme-black);
                    font-size: 16px;
                    padding: 15px 60px 15px 30px;
                    line-height: 110%;
                    font-weight: 400;
                    letter-spacing: -0.25px;
                    text-transform: uppercase;
										background: var(--white);
										clip-path: polygon(0 1%, 99.5% 0%, 86% 100%, 0% 100%);
										transition: 0.3s ease;
                }

              	&:hover {
									span {
										background: var(--red) !important;
										color: var(--white);
									}
								}
            }
        }

				&.grey-section {
						.benefits-content__item span {
							background: var(--light-grey);
						}
				}
		}

    &.text-and-video {

        .text-and-video__wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @mixin media 991 {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }

        .text-section {
            &__wrap {
                width: 40.86%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                flex: 0 0 auto;
                margin-left: ac(114px, 25px, 990, 1440);

                @mixin tab {
                    width: 45%;
                }

                @mixin media 991 {
                    width: 100%;

                    + .video-player {
                        padding-top: 35px;
                    }
                }
            }
        }

        .text-heading {
            width: 100%;

            h3 + p {
                padding-top: 8px;
            }
        }

        .text-content {
            padding-top: 5px;
            width: 100%;
        }

        .video-player {
            width: 100%;

            @mixin media 991 {
                + .text-section__wrap {
                    padding-top: 35px;
                    margin-left: 0;
                }
            }
        }
    }
}

.interior-jobs-section {
    &.white-section,
    &.grey-section {
        padding-top: ac(97px, 60px);
        padding-bottom: ac(97px, 60px);

        .heading {
            margin-bottom: ac(46px, 30px);
            h3 {
                margin-bottom: 8px;
            }
            + .jobs-list {
                margin-top: ac(-10px, 0px);
            }
        }
        .subtext {
            margin-left: ac(14px, 0px);
        }

        .grid-two-wrapper {
            grid-row-gap: ac(31px, 20px);
        }
    }

    &.vertical-jobs-list {
        padding-top: ac(105px, 60px);
        padding-bottom: ac(130px, 60px);
        .latest-jobs {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;

            .subtext {
                margin-left: 0;
            }

            @mixin media 769 {
                flex-direction: column;
                justify-content: flex-start;

                .heading {
                    width: 100%;
                    align-items: center;

                    & > * {
                        text-align: center;
                    }
                }

                .jobs-list {
                    width: 100%;
                }
            }
        }

        .heading {
            width: 40.86%;
            margin: 0 0 ac(15px, 30px);
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .shadow-btn {
                margin-top: 25px;
                .btn {
                    margin: 0;
                }
            }
        }

        .jobs-list {
            width: 49.31%;
            grid-template-columns: repeat(1,1fr);
            margin-bottom: 0;
        }
    }
}

.join-team-latest-jobs-section {
    padding-top: ac(136px, 70px);
    padding-bottom: ac(70px, 35px);
    box-shadow: none;

    .heading h3 {
        margin-bottom: 10px;
    }

    .subtext {
        margin-left: ac(13px, 0px);
    }

    .input-item {
        max-width: 355px;
    }

    .grid-two-wrapper {
        grid-row-gap: ac(30px, 20px);
    }

    .jobs-list {
        margin: 38px 0 ac(48px, 38px);
    }
}

.content-hub {
    &.white-section {
        .swiper-pagination {
            background: var(--light-gray);
        }
    }

    &.grey-section {
        .swiper-pagination {
            background: var(--white);
        }
    }

    &.contractor-advice {
        &.white-section,
        &.grey-section {
            padding-top: ac(77px, 70px);
            padding-bottom: ac(78px, 70px);

            .advice-heading h3 {
                margin-bottom: 20px;
            }
        }
    }
}

.clients-section {
    &.white-section,
    &.grey-section {
        padding: ac(100px, 60px) 0;

				.clients-list {
					padding-bottom: 0;

					.clients-item {
						margin-bottom: 0;
					}
				}

        .btn-wrap {
            padding-top: 23px;
            width: 100%;
            justify-content: center;
        }
    }
}

.testimonials-section {
    &.white-section,
    &.grey-section {
        padding-top: ac(70px, 60px);
    }

		&.white-section {
			& + .clients-section {
				&.white-section {
					padding-top: ac(20px, 50px);
				}
			}
		}
}

.sectors-section {
    &.white-section {
        .swiper-pagination {
            background: var(--light-gray);
        }
    }

    &.grey-section {
        .swiper-pagination {
            background: var(--white);
        }
    }

    &.white-section,
    &.grey-section {
        padding-bottom: 145px;

        +.contractor-sectors {
            padding-top: 45px;
        }
    }
}

.contractor-sectors {
    &.white-section {
        .swiper-pagination {
            background: var(--light-gray);
        }
    }

    &.grey-section {
        .swiper-pagination {
            background: var(--white);
        }
    }

    &.white-section,
    &.grey-section {
        padding-bottom: ac(130px, 60px);
    }
}

.video-section {
    padding-top: ac(105px, 60px);
    padding-bottom: ac(105px, 60px);
}

.sectors-list {
    + .video-section {
        padding-top: 65px;
    }
}

.hiring-section {
    &.white-section,
    &.grey-section {
        padding-top: ac(120px, 70px);
        padding-bottom: ac(115px, 70px);

        @mixin media 960 {
            padding-bottom: 120px;
        }

        @mixin media 769 {
            padding-bottom: 170px;
        }
    }

    &.white-section {
        .swiper-pagination {
            background: var(--light-gray);
        }
    }

    &.grey-section {
        .swiper-pagination {
            background: var(--white);
        }
    }
}

.white-section {
    + .presentation-section {
        &.white-section,
        &.grey-section {
            margin-top: 0;
        }
    }
}

.case-studies-section {
    &.white-section {
        .swiper-pagination {
            background: var(--light-gray);
        }
    }

    &.grey-section {
        .swiper-pagination {
            background: var(--white);
        }
    }

    &.white-section,
    &.grey-section {
        padding-top: ac(115px, 70px);
        padding-bottom: 150px;
    }
}

.gallery-section {
    &.white-section,
    &.grey-section {
        padding-top: ac(104px, 70px);
        padding-bottom: ac(80px, 100px);

        &.grid-gallery {
            padding-bottom: 30px;

            @mixin media 650 {
                padding-bottom: 100px;
            }
        }

        &.horizontal-gallery {
            + .text-section.text-and-video {
                padding-top: 70px;
            }
        }
    }

    &.white-section {
        .swiper-pagination {
            background: var(--light-gray) !important;
        }
    }

    &.grey-section {
        .swiper-pagination {
            background: var(--white) !important;
        }
    }
}

.process-stats {
    padding: 104px 0;

    .process-stats__list {
        grid-row-gap: 54px;
    }

    .process-number {
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: 34px;
        font-size: 69px;
        font-weight: 400;
        width: 100%;
        max-width: 157px;
        color: var(--white);
        height: 170px;
        background: linear-gradient(0deg, rgba(226,38,44,1) 0%, rgba(255,90,94,1) 100%);
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        transition: opacity 0.3s ease;
    }

    .process-title {
        @mixin max-line-length 3;
    }
}

.grey-section + .grey-section {
	padding-top: 0;
}

.form-builder, .form__builder-thanks {
	a {
		text-transform: none;
		color: var(--red);

		&:hover {
			text-decoration: underline;
		}
	}
}

.form-builder {
	a {
		font-size: ac(18px, 16px);
	}

	.checkBox span {
		&, * {
			font: inherit 16px / 165% var(--font-main) !important;
		}

		&:before {
			top: 5px;
		}
	}
}

.form__builder-thanks {
	font-size: ac(36px, 24px);
	text-transform: none;

	* {
		font: 600 inherit / inherit var(--font-main) !important;
	}
}

.form-builder-bg {
	clip-path: polygon(0 0,100% 0,80% 100%,0 100%,0 100%);
}

.form-builder {

    @mixin min-media 1026 {
        min-width: ac(520px, 500px);
        min-height: 400px;
    }

	.info-block {
		width: 100% !important;
	}

	.preview {
		@mixin aspect-ratio 820, 500;

		max-width: 45%;

		img {
			object-fit: contain;
		}

		@mixin min-media 1441 {
			max-width: 820px;
		}
	}

	@mixin media 1025 {
		.bg {
			clip-path: polygon(0 0,100% 0,95% 100%,0 100%,0 100%);
		}
	}
}


