.text-section {
    padding: 60px 0;

    &:not(.grey-section) {
        & + .text-section:not(.grey-section) {
            padding-top: 0 !important;
        }
    }


    &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @mixin media 900 {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    .text-red {
        color: var(--red);
    }

    .text-heading {
        width: 40.86%;
        margin-right: ac(60px, 30px);

        h3 {
            letter-spacing: -0.25px;

            + p, ul {
                padding-top: 15px;
            }
        }

        p, li {
            font-size: ac(21px, 16px);
            font-weight: 300 !important;
            line-height: 147.62%;
            color: var(--theme-black);

            a {
                font: inherit;
            }

            &:not(:last-child) {
                padding-bottom: ac(20px, 15px);
            }
        }
    }

    .text-content {
        width: 57.76%;

				h1 {
					font-size: ac(54px, 48px);
				}

        h1,h2,h3,h4,h5,h6,p {
            margin-bottom: 20px;
						color: var(--theme-black);
        }

        @mixin media 1200 {
            width: 52%;
        }

        p, li, ul {
            line-height: 172.22%;
            &:not(:last-of-type) {
                padding-bottom: ac(30px, 15px);
            }

            a {
                font: inherit;
            }
        }

        li {
            &:not(:last-of-type) {
                padding-bottom: ac(20px, 15px);
            }
        }
    }

    .btn-wrap {
        padding-top: ac(33px, 20px);
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .shadow-btn {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    @mixin media 900 {
        .text-heading {
            width: 100%;
        }

        .text-content {
            width: 100%;
        }

        .btn-wrap {
            flex-direction: row;
        }

        .text-heading + .text-content {
            padding-top: 20px;
        }
    }

    @mixin media 650 {
        .btn-wrap {
            flex-direction: column;
        }
    }
}