@media only screen and (min-width: 2048px) {
  .header {
    .container {
      max-width: 1536px !important;
    }
  }
}

@media only screen and (min-width: 1919px) {
  h1 {
    font-size: ac(86px, 33px);
    line-height: 1.4;
  }

  h2 {
    font-size: 60px;
  }

  h3, .h3 {
    font-size: 40px;
  }

  h4, .h4 {
    font-size: 26px;
  }

  h5, .h5 {
    font-size: 24px;
  }

  p {
    font-size: 20px;
  }

  .subtext {
    font-size: 23px;
  }

  .header {
    .container {
      width: 100%;
    }
  }

  .hiring-talent-section {
    .preview {
      width: 600px;
      height: 400px;
    }
  }

  .slide-label {
    max-width: 275px;

    h4, .h4 {
      max-width: 220px;
    }
  }
}

@media only screen and (min-width: 1819px) {
}

@media only screen and (min-width: 1619px) {
  .hiring-talent-section {
    .info-block {
      width: 65vw;
    }
    .preview {
      bottom: auto;
    }
  }

  .hub-offer {
    max-width: 385px;
  }

  .info-section {
    .decor-pattern {
      &:first-of-type {
        top: -350px;
        left: -480px;
      }
      &:nth-of-type(2) {
        bottom: -240px;
        right: -600px;
      }
    }
  }
}

@media only screen and (max-width: 1281px) {
  .header {
    .links {
      display: none;
    }

    .contact-btn {
      margin-left: 20px;
    }
  }

  .job-item {
    flex-direction: column;
    align-items: flex-start;

    object {
      flex-direction: column;
      align-items: flex-start;
    }

    h5, .h5 {
      max-width: 100%;
    }
    .job-details {
      margin: 7px 0 0 0;
    }
  }
}

@media only screen and (max-width: 901px) {
  .hero-screen {
    .job-search-form {
      .treeselect-input__operators {
        right: 14%;
      }
    }
  }

  .latest-jobs {
    .job-search-form {
      .btn-ordinary {
        margin: auto 0;
      }
    }
  }

  .content-wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .hub-offer {
    max-width: 100%;
    margin-bottom: ac(60px, 35px, 375, 901);
  }

  .history-section {
    .container {
      padding: 0 20px;
    }
  }

  .articles-slider {
    overflow: visible !important;
  }

  .info-section {
    .job-search-form {
      .treeselect-input__operators {
        right: 13%;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .mobile-search input {
    background-image: url('../images/search-outline.svg');
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: top 50% left 16px;
    padding-left: 40px;
  }
}

@media only screen and (max-width: 769px) {
  .sectors-slider {
    margin: 0 auto;
  }

  .navigation {
    display: flex;
  }

  .hiring-talent-section {
    .preview {
      bottom: ac(-100px, -70px, 375, 769);
      right: ac(-240px, -20px, 375, 769);
    }

    .btn-wrap {
      flex-direction: column;
      align-items: flex-start;

      .btn:not(:last-of-type),
      .shadow-btn:not(:last-child) {
        margin-right: 0;
        margin-bottom: 17px;
      }
    }
  }
}

@media only screen and (max-width: 651px) {
  .sectors-slider {
    .swiper-slide {
      width: 100%;
    }
  }

  .sectors-list {
    .sector-card {
      height: auto;

      .content {
        margin-left: 0;
        padding-right: 20px;
      }
    }
  }

  .sector-card {
    max-width: 100%;
    height: 100%;
    width: 100% !important;
    max-height: 100%;

    .preview {
      position: relative;
      height: 195px;
      width: 100%;

      &-img {
        clip-path: polygon(100% 0, 100% 30%, 50% 100%, 0 100%, 0 0);
      }

      .sector-icon {
        bottom: 0;
        right: ac(25px, 15px, 375, 651);
      }
    }

    .content {
      opacity: 1;
      margin-left: 0;
      padding-left: 20px;
      max-width: 100%;
      height: auto;
    }
  }

  .upload-cv {
    top: 25%;
    &:hover {
      top: 23%;
    }
  }
}

@media only screen and (max-width: 551px) {
  .decor-right-bottom:before {
    bottom: 0;
  }
  .subtext {
    line-height: 152%;
  }

  .job-item {
    .icon-arrow-next {
      top: auto;
      transform: translateY(0);
      right: 10px;
      bottom: 18px;
    }
  }

  .history {
    &-block {
      .subtext {
        margin-bottom: 5px;
      }
      p:not(.subtext) {
        line-height: 160%;
      }
    }
  }

  .info-section {
    .decor-pattern {
      &:first-of-type {
        top: -37vw;
        left: -42vw;
      }
      &:nth-of-type(2) {
        bottom: -16vw;
        right: -30vw;
      }
    }
  }
}

@media only screen and (max-width: 421px) {
  .swiper-slide {
    width: 100%;
  }

  .article-bg {
    .shadow-wrap {
      &.red {
        width: ac(86px, 73px, 375, 421);
      }
    }
  }
}

@media only screen and (max-width: 361px) {
  .upload-cv {
    top: 30%;
    &:hover {
      top: 28%;
    }
  }

  .history-section {
    .container {
      padding: 0;
    }
  }
}
