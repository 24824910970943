/* ---- Testimonials section ---- */

.testimonials-section {
  padding: ac(145px, 80px) 0 ac(190px, 140px);
  overflow: hidden;

  .decor-pattern {
    right: -40%;
    bottom: -72%;
  }

  .pagination-main {
    bottom: ac(-64px, -70px) !important;
  }

  .container {
    position: relative;
    z-index: 25;
  }

  &-second {
    &:before {
      z-index: 1;
    }
  }
}

.testimonials-heading {
  text-align: center;
  max-width: 590px;
  margin: 0 auto 40px;

  h3 {
    letter-spacing: -0.25px;
    margin-bottom: 10px;
    color: var(--red);
  }
}

.testimonials-sliders {
  display: flex;
  justify-content: space-between;

  @mixin media 1150 {
    flex-direction: column;
  }
}

.clients-wrap {
  box-shadow: var(--main-shadow);
  height: 247px;
  max-width: 220px;

  @mixin media 1150 {
    margin: 0 auto 120px;
  }
}

.client-preview-slider {
  flex: 0 0 auto;
  height: 100%;
  margin: 0;
  background: var(--white);

  .shadow-wrap {
    position: absolute;
    z-index: 10;

    &.blue {
      width: 36px;
      height: 62px;
    }

    &.red {
      width: 55px;
      height: 91px;
    }
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    height: 80px;
    box-shadow: none;

    img {
      max-width: 182px;
    }
  }
}

.testimonials-wrap {
  position: relative;
  margin-top: 21px;

  &:before {
    font-family: "icomoon";
    content: "\e90e";
    left: -90px;
    position: absolute;
    top: -6px;
    font-size: 50px;
    color: var(--red);
  }

  @mixin media 1150 {
    &:before {
      left: 0;
      top: -80px;
    }

    .swiper-pagination {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}

.testimonials-swiper {
  max-width: ac(766px, 560px, 1150, 1440);
  margin: 0;

  @mixin media 1150 {
    max-width: 100%;
  }

  .swiper-slide {
    p {
      font-weight: 500;
      font-size: 21px;

      &:not(:last-child) {
        margin-bottom: 28px;
      }
    }
  }
}
