/* Default Input / Textarea */

.input-item {
  width: 100%;
  max-width: 350px;
  filter: var(--shadow-filter);
	transform: translateZ(0);
}

input:not([type="checkbox"]),
textarea,
.upload-input {
  position: relative;
  border-radius: 0;
  width: 100%;
  resize: none;
  padding: 16px 20px 16px 18px;
  color: var(--theme-black);
  font-size: ac(16px, 14px);
  transition: 0.3s ease-in-out all;
  border: 1px solid var(--black);
  background: transparent;
  font-family: var(--font-main);
  line-height: ac(24px, 14px);

  &::placeholder {
    text-transform: uppercase;
    color: var(--theme-black);
    font-size: ac(16px, 12px);
    font-weight: 600;
  }

  @mixin transparent-bg-input var(--theme-black);
}

.upload-input {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: 0.25s ease;

  &__title,
  &__file-name {
    text-transform: uppercase;
    cursor: pointer;
    font-size: ac(16px, 12px);
    font-weight: 600;
    transition: 0.25s ease;
  }

  &__title {
    color: var(--theme-black);
    margin-right: ac(21px, 15px);
    pointer-events: none;
  }

  &__file-name {
    color: var(--dark-grey);
    text-overflow: ellipsis;
    width: 280px;
    white-space: nowrap;
    overflow:hidden;

    @media only screen and (max-width: 460px) {
      max-width: 220px;
      width: 100%;
    }
    
    @media only screen and (max-width: 415px) {
      max-width: 170px;
    }
  }

  input {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    cursor: pointer;
  }

  &:hover {
    background: var(--theme-black);

    .upload-input__file-name, .upload-input__title {
      color: var(--white);
    }
  }
}

textarea {
  height: ac(120px, 96px);

  &::-webkit-scrollbar {
      width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9fa1a1;
  }
}

/* Select 2 */
.select-item {
  select {
    display: none;
  }
}

.select2-dropdown {
  border: none !important;
  border-radius: 0 !important;
}

.select2-results__option {
  font-family: var(--font-main);
  transition: all 0.3s ease-in-out;
}

.select2-container--default .select2-results__option--selected {
  background: var(--theme-black);
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background: var(--red);
  color: var(--white);
}

.select2-container--default .select2-results > .select2-results__options {
  background: var(--black);
  color: var(--white);
  border: none;
}

.select2-container--open {
  .select2-dropdown--below {
    border: none;
    overflow: hidden;
  }
  .select2-selection__arrow {
    &::before {
      transform: rotate(-180deg);
    }
  }
}

.select2-results__options {
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--white);
  }
}

form {
  .select2-container {
    width: 100%;
  }

  .select2-container--default .select2-selection--single {
    background: transparent;
    border: 1px solid var(--theme-black);
    border-radius: 0;
    font-size: ac(16px, 12px);
    font-weight: 600;
    padding: 16px 20px 16px 18px;
    text-transform: uppercase;
    height: ac(60px, 50px);

    .select2-selection__rendered {
      color: var(--theme-black);
      padding: 0;
      line-height: initial;
			max-width: calc(100% - 30px);
    }

    .select2-selection__placeholder {
      color: var(--theme-black);
      text-transform: uppercase;

      @mixin mob {
        text-overflow: ellipsis;
        max-width: 230px;
        overflow: hidden;
      }

      @mixin mob-sm {
        max-width: 190px;
      }
    }

    .select2-selection__arrow {
      width: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      right: 15px;

      &::before {
        font-family: "icomoon";
        content: "\e909";
        font-size: 14px;
        transition: transform 0.3s ease;
      }

      b {
        display: none;
      }

      @mixin media 551 {
        &::before {
          font-size: 8px;
        }
      }
    }
  }
}

/* Custom checkbox */
.checkBox {
  grid-column: 1 / -1;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;

  span {
    padding-left: 30px;
    position: relative;
    display: block;
    flex: 0 0 auto;
    font-size: 14px;
    color: var(--theme-black);
    transition: all 0.3s ease-in-out;
    word-break: break-word;
    width: 100%;

    &:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border: 1px solid var(--theme-black);
      left: 0;
      top: 4px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: var(--white);
    }

    a {
      text-transform: none;
      color: var(--red);
      &:hover {
        text-decoration: underline;
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked + span {
      &:before {
        content: "\2713";
        background: var(--red);
        border: 1px solid var(--red);
      }
    }
  }

  &:hover {
    span:before {
      border: 1px solid var(--red);
    }
  }
}

/* Radio */
.radioInput {
  grid-column: 1 / -1;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;

  span {
    padding-left: 30px;
    position: relative;
    display: block;
    flex: 0 0 auto;
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-black);

    transition: all 0.3s ease-in-out;
    word-break: break-word;
    width: 100%;

    &:before {
      content: "";
      position: absolute;
      size: 16px;
      border-radius: 50%;
      border: 1px solid var(--blue-green);
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      display: flex;
      align-items: center;
      justify-content: center;

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      font-size: 11px;
      color: var(--white);

      transition: 0.25s ease;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + span {
      color: var(--blue-green);

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='radio' transform='translate(-170 -767)'%3E%3Cg id='%D0%9F%D1%80%D1%8F%D0%BC%D0%BE%D1%83%D0%B3%D0%BE%D0%BB%D1%8C%D0%BD%D0%B8%D0%BA_170' data-name='%D0%9F%D1%80%D1%8F%D0%BC%D0%BE%D1%83%D0%B3%D0%BE%D0%BB%D1%8C%D0%BD%D0%B8%D0%BA 170' transform='translate(170 767)' fill='%23008199' stroke='%23008199' stroke-width='2'%3E%3Crect width='16' height='16' rx='8' stroke='none'/%3E%3Crect x='1' y='1' width='14' height='14' rx='7' fill='none'/%3E%3C/g%3E%3Cpath id='%D0%9A%D0%BE%D0%BD%D1%82%D1%83%D1%80_3' data-name='%D0%9A%D0%BE%D0%BD%D1%82%D1%83%D1%80 3' d='M713.542 631.225l2.394 2.394 4.89-4.89' transform='translate(-539.184 143.826)' fill='none' stroke='%23fff' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
  }

  &:hover {
    span {
      color: var(--blue-green);
    }
  }
}



