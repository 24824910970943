/* ------------- Buttons ------------- */

/* Create buttons including span inside !!! */

.btn {
  display: block;
  width: 145px;
  height: 50px;
  background: var(--blue-green);
  text-transform: uppercase;
  color: var(--white);
  position: relative;
  overflow: hidden;
  border: none;
  z-index: 1;
  clip-path: var(--right-corner);
  transition: background 0.1s ease;
  font-size: 16px;
  cursor: pointer;

  &.loader {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
    }

    &::before {
      content: '';
      position: static;
      border: 4px solid rgba(255, 255, 255, .7);
      border-left-color: transparent;
      border-radius: 50%;
      flex: 0 0 auto;
      margin-left: -15px;
      width: 36px;
      height: 36px;
      animation: spin 1s linear infinite;
    }

    &.white {
      &::before {
        border-color: var(--theme-black);
        border-left-color: transparent;
      }
    }
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    text-transform: uppercase;
    font-weight: 600;

    padding: 13px 40px 13px 27px;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;

    &:before,
    &:after {
      position: absolute;
      content: "";
      bottom: 0;
      background: var(--blue-green);
      transition: all 0.3s ease;
    }

    &:after {
      width: 3px;
      height: 0;
      right: 10px;
      transform: skewX(-22deg);
    }

    &:before {
      right: 0;
      width: 0;
      height: 2px;
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: var(--blue-green);
    transition: all 0.3s ease;
  }

  &:before {
    height: 0;
    left: 0;
    width: 2px;
  }

  &:after {
    width: 0;
    height: 2px;
  }

  &:hover:not(.loader) {
    background: transparent;
    &:after {
      width: 100%;
    }
    &:before {
      height: 100%;
    }
    span {
      color: var(--blue-green);
      &:after {
        height: 100%;
      }
      &:before {
        width: 100%;
      }
    }
  }

  /* Sizes */
  &.xxl {
    width: 250px;
    clip-path: var(--right-corner-xxl);

    span {
      padding: 13px 51px 13px 40px;
    }

    span:after {
      right: 13px !important;
      transform: skewX(-29deg) !important;
    }
  }

  &.xl {
    width: 235px;
    span:after {
      width: 4px;
      right: 16px;
      transform: skewX(-33deg);
    }
  }

  &.lg {
    width: 195px;
    span::after {
      right: 13px;
      transform: skewX(-29deg);
    }
  }

  &.md {
    width: 170px;
    span:after {
      right: 11px;
      transform: skewX(-26deg);
    }
  }

  &.sm {
    width: 155px;
  }

  /* Colors */
  &.ghost {
    background: transparent;
    color: var(--black);
    border: 3px solid transparent;
    border-right: 0;
    &:after,
    &:before {
      display: none;
    }

    span {
      color: var(--black);
      border: 1px solid var(--black);

      &:before {
        display: none;
      }

      &:after {
        height: 100%;
        width: 2px;
        background: var(--black);
      }
    }

    &:hover {
      span {
        color: var(--white);
        background: var(--black);
      }
    }
  }

  &.black {
    background: var(--theme-black);
    span {
      color: var(--white);
      &:before,
      &:after {
        background: var(--theme-black);
      }
    }
    &:before,
    &:after {
      background: var(--theme-black);
    }

    &:hover {
      background: transparent;
      span {
        color: var(--theme-black);
      }
    }
  }

  &.red {
    background: var(--red);
    span {
      color: var(--white);
      &:before,
      &:after {
        background: var(--red);
      }
    }
    &:before,
    &:after {
      background: var(--red);
    }

    &:hover {
      background: transparent;
      span {
        color: var(--red);
      }
    }
  }

  &.white {
    background: var(--white);
    span {
      color: var(--theme-black);
      &:before,
      &:after {
        background: var(--white);
      }
    }
    &:before,
    &:after {
      background: var(--white);
    }

    &:hover {
      background: transparent;
      span {
        color: var(--white);
      }
    }
  }

  &.white-text {
    &:hover {
      span {
        color: var(--white);
      }
    }
  }

  /* Type */
  &.left-clip {
    clip-path: polygon(11% 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    padding-left: 40px;

    span {
      &:after {
        right: -1px !important;
        transform: none !important;
      }
    }

    &:before {
      width: 3.5px;
    }

    &:hover {
      &:before {
        left: 13px;
        transform: skewX(-29deg);
      }

      span:before {
        width: 130%;
      }
    }
  }
}

.btn-ordinary {
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--theme-black);
  font-size: ac(16px, 12px);
  font-weight: 500;
  border: 1px solid var(--theme-black);
  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--white);
    background: var(--theme-black);
  }

  .black-ghost {
    color: var(--theme-black);
    border: 1px solid var(--theme-black);

    &:hover {
      color: var(--white);
      background: var(--theme-black);
    }
  }

  &.white {
    color: var(--white);
    border: 1px solid var(--white);

    &:hover {
      color: var(--theme-black);
      background: var(--white);
    }
  }

  &.blue {
    background: var(--blue-green);
    color: var(--white);
    border-color: transparent;
    box-shadow: var(--main-shadow);

    &:hover {
      border-color: var(--blue-green);
      color: var(--blue-green);
      background: transparent;
    }
  }
}

.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:container(width <= 500px) {
    flex-direction: column;
  }

  .btn {
    flex: 0 0 auto;
  }

  .btn:not(:last-of-type),
  .shadow-btn:not(:last-child) {
    margin-right: ac(20px, 10px);
  }
}

@mixin media 551 {
  .btn:not(.profile-cv-form .btn, .request-btn) {
    font-size: 12px;
    width: 115px;
    height: 40px;

    span:after {
      right: 7px;
      transform: skewX(-21deg);
    }

    /* Sizes */
    &.xxl {
      width: 195px;

      span:after {
        right: 10.5px !important;
        transform: skewX(-28deg);
      }
    }

    &.xl:not(.profile-cv-form .btn) {
      width: 175px;
      span:after {
        right: 11px;
        transform: skewX(-31deg);
      }
    }

    &.lg:not(.profile-cv-form .btn) {
      width: 154px;
      span:after {
        right: 10px;
        transform: skewX(-28deg);
      }
    }

    &.md {
      width: 135px;
      span:after {
        right: 9px;
        transform: skewX(-25deg);
      }
    }

    &.sm {
      width: 120px;
      span:after {
        right: 7px;
        transform: skewX(-23deg);
      }
    }

    /* Type */
    &.left-clip {
      &:hover {
        &:before {
          left: 13px;
          transform: skewX(-34deg);
        }
      }
    }
  }
}
