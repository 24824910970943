@use "sass:math";

.fp-watermark {
  display: none;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--light-grey);
  color: var(--theme-black);
  font-family: var(--font-main);

  &.disabled {
    overflow-y: scroll;
    position: fixed;
    width: 100%;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9fa1a1;
  }
}

#site {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#bold-credits {
  width: 150px;
  margin: ac(30px, 20px) auto;
  transition: all 0.3s ease-in-out;

  &::before {
    display: none;
  }

  &:hover {
    transform: scale(1.14);
  }
}

.main {
  flex-grow: 1;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.container {
  margin: 0 auto;
  max-width: 1160px;
  width: perc(1160);

  @mixin media 901 {
    width: 100%;
    padding: 0 ac(35px, 15px, 320, 375);
  }
}

.logo:hover {
  transform: scale(1.05);
}

section {
  position: relative;
  box-shadow: 0 30px 20px -20px var(--shadow-color);
  overflow: hidden;
}

[data-simplebar] {
  --scrollbar-width: 4px!important;
}

/* Common selectors -start */

.content-wrap {
  display: flex;
}

.shadow {
  &-wrap {
    filter: var(--shadow-filter);
		transform: translateZ(0);
  }

  &-btn {
    filter: var(--shadow-button);
		transform: translateZ(0);
  }
}

.slider-wrap {
  position: relative;
  max-width: 100%;
}

.interior-page {
  min-height: ac(615px, 500px);
  padding-top: ac(105px, 80px);
  overflow: visible;

  display: flex;
  align-items: center;
  justify-content: center;

  .offer {
    max-width: 670px;
    text-align: center;
    margin: 0 auto;

    &-text {
      margin-top: 7px;
    }
  }
}

.select-layout {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.active {
    display: block;
  }
}

.main-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #4f0e10;
    pointer-events: none;
    opacity: .6;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.decor-pattern {
  position: absolute;
  right: -40%;
  bottom: -60%;
  width: ac(1330px, 420px);
  height: ac(1100px, 320px);
  pointer-events: none;
  opacity: 0.6;
}

.hover-link {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background: var(--red);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: var(--red);
    &::after {
      width: 100%;
      left: 0;
      background: var(--red);
    }
  }
}

/* Common selectors -end */

/* Grid Wrappers Style */
.grid-four-wrapper {
  grid-column-gap: ac(30px, 20px);
  grid-row-gap: ac(55px, 35px);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @mixin media 1024 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 650 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-three-wrapper {
  grid-column-gap: ac(30px, 20px);
  grid-row-gap: ac(55px, 35px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  margin-bottom: ac(140px, 70px);

  @mixin media 1280 {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 650 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-two-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @mixin media 769 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-one-wrapper {
  grid-row-gap: ac(55px, 35px);
  display: grid;
  grid-template-columns: 1fr;

  margin-bottom: ac(140px, 70px);
}

/* Decor items Style */
.decor-right-bottom,
.decor-left-top,
.decor-right-center,
.decor-right-top,
.decor-center-bottom {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.55;
    pointer-events: none;
  }

  .container {
    z-index: 5;
    position: relative;
  }
}

.decor-right-bottom {
  &:before {
    bottom: -40px;
    right: 0;
    transform: translateX(41.8%) translateY(44.5%) rotate(-6deg);
    width: ac(1334px, 420px);
    height: ac(1022px, 320px);
    background-image: url("../images/decor-items/decor-item-1.svg");
  }
}

.decor-left-top {
  &:before {
    top: 0;
    left: 0;
    transform: translateX(-41.8%) translateY(-44.5%) rotate(-186deg);
    width: ac(1334px, 420px);
    height: ac(1022px, 320px);
    background-image: url("../images/decor-items/decor-item-1.svg");
    pointer-events: none;
  }
}

.decor-right-center {
  &:before {
    top: 0;
    right: -35px;
    transform: translate(40%, -10%);
    width: ac(1002px, 540px);
    height: ac(1308px, 730px);
    background-image: url("../images/decor-items/decor-vertical-lg.svg");
    z-index: 1;
  }
}

.decor-right-top {
  &:before {
    right: 0;
    top: 0;
    width: ac(1022px, 540px);
    height: ac(1335px, 730px);
    transform: translate(15%, -50%) rotate(-45deg);
    background-image: url("../images/decor-items/decor-vertical-lg.svg");
  }
}

.decor-center-bottom {
  &:before {
    top: 50%;
    transform: translate(-20%, -34%) rotate(-80deg) scaleX(-1);
    width: ac(1352px, 900px, 375, 768);
    height: ac(1400px, 900px, 375, 768);
    background-image: url("../images/decor-items/decor-vertical-lg.svg");

    @mixin tab-sm {
      top: 70%;
      left: 10%;
    }

    @mixin desk-md {
      right: auto;
    }
  }
}

[class$="-swiper-section"] {
  .top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: ac(40px, 20px);

    .swiper-buttons {
      margin-left: 20px;
      flex-shrink: 0;
    }
  }
}

/* Styles for first section if it 100vh of viewport */
[class$="first-section"] {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .container {
    position: relative;
    padding: 100px 0;
    z-index: 6;
  }

  /* styles for not 100vh height section */
  &[data-not-full-height] {
    min-height: initial;
    padding: ac(150px, 120px) ac(80px, 60px);
  }

  &.align-end {
    align-items: flex-end;

    .cont {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &.bg-color {
    background-color: var(--blue-green);
  }

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    z-index: 1;
  }
}

.first-section-img-layer {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @mixin media 769 {
    width: 100%;
    z-index: -1;
  }
}


/* ---- Main page ---- */
.upload-cv {
  position: fixed;
  right: -100%;
  top: 40%;
  transform: rotate(-90deg);
  width: ac(208px, 150px);
  height: ac(44px, 32px);
  background: var(--red);
  display: flex;
  align-items: center;
  padding-left: ac(30px, 22px);
  z-index: 900;
  cursor: pointer;
  clip-path: var(--right-top);
  transition: all 0.2s ease-in-out;

  p {
    text-transform: uppercase;
    font-size: ac(21px, 15px);
    font-weight: 400;
    color: var(--white);
  }

  &:hover {
    top: 38%;
  }
}

/* First screen */
.hero-screen {
  padding: ac(245px, 265px) 0 ac(210px, 138px);
  min-height: ac(760px, 546px);

  &.home-page {
    .main-bg {
      &.with-mobile {
        @media (min-width: 552px) {
          display: none;
        }
      }

      &:not(.with-mobile) {
        @media (max-width: 551px) {
          display: none;
        }
      }
    }
  }
}

.offer {
  h1 {
    span {
      font-weight: 600;
      color: var(--red);
      display: inline;
    }
  }

  &-text {
   &, p {
    max-width: 670px;
    margin: 12px 0 28px;
    font-size: ac(25px, 18px);
    color: var(--white);
    font-weight: 500;
   }
  }

  .btn-wrap {
    flex-wrap: nowrap;
  }
}

/* Sectors on Main Page */
.sectors {
  padding: ac(165px, 210px) 0 ac(175px, 176px);
  background: var(--white);

  .slider-wrap {
    display: flex;
  }

  h3, .h3 {
    margin-bottom: ac(50px, 30px);
    color: var(--blue-green);
  }
}

.slider-wrap {
  .swiper-pagination {
    bottom: -55px;
  }
}

.preview {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.sectors-slider, .sectors-slider-second {
  overflow: visible;
  width: 278px;
  margin-left: 0;
}

.sectors-slider {
  @mixin media 415 {
    width: 100%;
  }
}

/* Big Info Section */
.info-section,
.interior-jobs-section {
  position: relative;
  overflow: hidden;
  padding: ac(135px, 80px) 0 ac(140px, 90px);
  z-index: 10;

  .job-search-form {
    justify-content: center;

    .keyword-search,
    .comboTreeInputWrapper {
      background: var(--white);
    }
  }

  .view-more {
    margin: 0 auto;
  }

  .decor-pattern {
    display: block;
    position: absolute;
    width: ac(1330px, 420px);
    height: ac(1100px, 320px);
    z-index: -1;
    pointer-events: none;
    opacity: 0.6;

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &:first-of-type {
      left: -37vw;
      top: -34vw;
    }

    &:nth-of-type(2) {
      bottom: -16vw;
      right: -44vw;
    }
  }
}

.latest-jobs {
  .heading {
    margin-bottom: 0;
  }

  .job-search-form {
    margin-top: 45px;
  }
}

.heading {
  text-align: center;
  max-width: 588px;
  margin: 0 auto 48px;

  h3, .h3 {
    margin-bottom: 12px;
    letter-spacing: -0.25px;
  }

  p {
    color: var(--theme-black);
    @mixin media 551 {
      line-height: 155%;
    }
  }

  &.red {
    h3, .h3 {
      color: var(--red);
    }
  }

  &.blue-green {
    h3, .h3 {
      color: var(--blue-green);
    }
  }
}

.jobs-list {
  position: relative;
  margin: 38px 0 ac(50px, 38px);
  grid-auto-rows: 1fr;
  min-height: 90px;

  h2 {
    width: 100%;
    position: absolute;
    text-align: center;

    font-size: ac(44px, 30px);
  }

  li {
    width: 100%;
  }

  .job-item {
    height: 100%;
  }
}

.job-item {
  background: var(--white);
  position: relative;
  padding: ac(22px, 28px) ac(40px, 15px) ac(22px, 28px) 15px;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: var(--main-shadow);
  width: 100%;

  display: flex;
  align-items: center;

  object {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  h5, .h5 {
    max-width: 246px;
    width: 100%;
    text-transform: capitalize;
    flex: 0 0 auto;
  }

  .job-details {
    margin-left: ac(20px, 10px, 1281, 1440);
    max-width: 240px;
    width: 100%;

    &__item {
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;

      @mixin max-line-length 2;
    }

    i {
      margin-right: 8px;
      font-size: 12px;
      color: var(--red);
      line-height: 155%;
      margin-top: 3px;
			display: inline-block;

      &.icon-pound {
        margin-top: 6px;
      }
    }

    @mixin media 551 {
      p {
        font-size: 12px;
      }

      i {
        margin-top: 0;

        &.icon-pound {
          margin-top: 4px;
        }
      }
    }
  }

  .icon-arrow-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 22px;
    z-index: 10;
    &:before {
      font-size: 30px;
      color: var(--red);
    }
  }

  .desc {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 14px ac(98px, 35px) 14px 30px;
    color: var(--white);
    font-size: ac(16px, 12px);
    background: var(--blue-green);
    text-transform: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

		> * {
			display: inline;
		}

    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    a {
      color: var(--white);
      text-transform: none;
      font-size: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .salary-inner {
    p {
      display: inline;
    }
  }

  &:hover {
    .desc {
      opacity: 1;
      visibility: visible;
    }
    .icon-arrow-next:before {
      color: var(--white);
    }
  }

  @mixin media 551 {
    min-height: 200px;
  }
}

.history {
  &-section {
    margin: ac(145px, 70px) 0 ac(165px, 70px);
  }

  &-block {
    color: var(--white);
    box-shadow: var(--main-shadow);
    text-align: center;
    background: var(--dark-grey);
    padding: ac(65px, 30px) ac(98px, 16px);

    .subtext {
      margin-bottom: 9px;
      color: var(--white);
    }

    p:not(.subtext) {
      font-size: ac(21px, 16px);
      font-weight: 300;
      line-height: 150%;
    }

    .btn-wrap {
      justify-content: center;
      margin-top: ac(34px, 23px);
      .shadow-wrap:first-child {
        margin-right: 18px;
      }
    }
  }
}

.hiring-talent-section {
  display: flex;
  align-items: center;
  position: relative;

  .shadow-wrap {
    width: 100%;
    position: relative;

    @mixin media 901 {
      width: auto;
    }
  }

  &.form-builder {
    .shadow-wrap {
      @mixin media 901 {
        width: 100%;
      }
    }
  }

  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 60%;
    height: 100%;
    background: var(--white);
    clip-path: polygon(0 0%, 100% 0, 89% 100%, 0 100%, 0 100%);

    @mixin media 901 {
      width: 100%;
    }
  }

  .info-block {
    position: relative;
    color: var(--theme-black);
    padding: ac(52px, 30px) 54px ac(52px, 60px) 38px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .subtext {
      margin: ac(12px, 0px) 0;
    }

    p:not(.subtext) {
      line-height: 165%;
    }

    .btn-wrap {
      margin-top: ac(14px, 21px);
    }

    .content {
      max-width: 460px;
      position: relative;
      z-index: 5;
      margin-right: 50px;

      @mixin media 901 {
        margin-right: 0;
      }
    }
  }

  .preview {
    width: ac(470px, 261px);
    height: ac(295px, 233px);

    @mixin media 901 {
      position: absolute;
      bottom: -12vw;
      right: -33vw;
    }

    @mixin media 769 {
      bottom: ac(-100px, -70px, 375, 769);
      right: ac(-240px, -20px, 375, 769);
    }
  }
}

/* Content Hub */
.content-hub {
  position: relative;
  background: var(--white);
  padding: ac(206px, 70px) 0 ac(90px, 150px);
  overflow: hidden;

  .container {
    position: relative;
    z-index: 5;
  }

  .slider-wrap {
    max-width: 1247px;

    @mixin media 901 {
      max-width: 100%;
      width: 100%;
    }

    @mixin min-media 2536 {
      max-width: 82vw;
    }
  }

  .swiper-pagination {
    bottom: -6px;
  }

  .navigation {
    bottom: -80px;
  }

  &.interior {
    padding: ac(118px, 74px) 0 ac(90px, 150px, 768, 1440);

    .navigation {
      @mixin media 769 {
        bottom: -95px;
      }
    }

    .subtext {
      margin-top: 7px;
      margin-bottom: 20px;
      line-height: 145%;
    }

    .hub-offer {
      margin-right: ac(90px, 0px);
    }

    .swiper-pagination {
      background: var(--white);

      max-width: 768px;
    }
  }
}

.decor-triangle {
  width: ac(350px, 152px);
  height: ac(380px, 164px);
  position: absolute;
  left: 0;
  top: ac(-60px, -5px);
  background: linear-gradient(
    45deg,
    rgba(226, 38, 44, 1) 0%,
    rgba(255, 90, 94, 1) 50%
  );
  clip-path: polygon(0 0, 0% 100%, 100% 0);

  &.sm {
    width: ac(270px, 152px);
    height: ac(290px, 164px);
  }
}

.hub-offer {
  color: var(--theme-black);
  max-width: 320px;
  flex: 0 0 auto;
  margin-right: ac(90px, 0px);

  .subtext {
    margin: ac(12px, 7px) 0;
  }
  p:not(.subtext) {
    line-height: 175%;
  }
  .btn {
    margin-top: 12px;
  }
}

.article {
  width: 400px;
  height: ac(300px, 290px);
  padding: 0 0 ac(22px, 34px) ac(32px, 20px);
  box-shadow: 10px 5px 15px var(--shadow-color);
  overflow: hidden;
  position: relative;

  display: flex;
  align-items: flex-end;

  &-bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 102%;
    z-index: 10;

    .back {
      width: 100%;
      height: 102%;
      position: absolute;
      left: -2px;
      bottom: 0;
      background: var(--light-grey);
      clip-path: polygon(0 0, 70px 0, 100% 100%, 0% 100%);
    }
  }

  .shadow-wrap {
    position: absolute;
    display: block;
    z-index: 10;
		transform: translateZ(0);
  }

  &-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 90%;
    height: 235px;
  }

  &-desc {
    position: relative;
    z-index: 15;
    max-width: ac(200px, 150px);
    overflow: hidden;
    max-height: 154.5px;


    .date {
      max-width: 75px;
      color: var(--red);
      font-size: ac(16px, 12px);
    }

    h5, .h5 {
      text-transform: none;
      @mixin max-line-length 3;
    }
  }

  &:after {
    content: "\e904";
    font-family: "icomoon";
    font-size: 42px;
    position: absolute;
    color: var(--white);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    background: rgb(91 91 91 / 87%);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
		transform: translateZ(0);
  }

  &:hover {
    &:not(.insight-related-card) {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.shadow-wrap {
  &.blue {
    height: 118px;
    left: -3px;
    top: 0;
    width: 70px;
    filter: drop-shadow(5px 5px 6px var(--shadow-color));

    .triangle {
      height: 100%;
      background: var(--blue-green);
      clip-path: polygon(0 0, 0 -10%, 100% 55%, 0 90%);
    }

    img {
      width: 100%;
    }
  }

  &.red {
    right: -2px;
    bottom: -8px;
    width: 102px;
    height: 166px;
    filter: none;

    .triangle {
      height: 100%;
      background: var(--red);
      clip-path: polygon(0 0%, 0% 0%, 100% 58%, 0% 100%);
      transform: rotate(180deg);
    }

    @mixin media 321 {
      .triangle {
        clip-path: polygon(0 0%, 0% 0%, 100% 61%, 0% 100%);
      }
    }
  }
}

.articles-slider {
  height: ac(335px, 315px);
}

/* page 404 */
.page-404 {
  background: url("../images/decor-items/popup-decor.svg") no-repeat center /cover;

  .text-404 {
    font-size: ac(100px, 80px);
  }
}

/* terms-page */
.terms-section {
  padding: 200px 0 ac(100px, 50px);

  a {
    text-decoration: underline;
    display: inline;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  ul {
    list-style: circle;
    padding-left: 15px;
    margin-bottom: 20px;
  }

  ol {
    list-style: decimal;
    padding-left: 15px;
    margin-bottom: 20px;
  }
}

.count-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.testimonials-swiper-section-2 {
  .cont {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: ac(100px, 30px);
    grid-row-gap: ac(50px, 30px);
  }

  .testimonials-swiper-2 {
    overflow: hidden;
  }

  @mixin media 901 {
    .cont {
      grid-template-columns: 1fr;
    }
  }
}
.grecaptcha-badge {
  z-index: 8995;
}
