/* Members list */
.members-list, .process-stats__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 16px;
  grid-auto-rows: 1fr;

  @mixin tab-sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @mixin media 480 {
    grid-template-columns: 1fr;
    justify-content: center;

    .member-item, .process-stats__item {
      max-width: 100%;
    }
  }
}

.member-item, .process-stats__item {
  max-width: 376px;
  width: 100%;

  a {
    display: block;
    width: 100%;
    text-transform: none;
  }

  .member-name {
    text-transform: capitalize;
    margin-bottom: 8px;
  }

  &:hover {
    .member-preview, .process-preview {
      &:before,
      .read-more-btn {
        opacity: 1;
      }
      &:after {
        bottom: 0;
      }

      .process-number {
        opacity: 0;
      }
    }
  }
}

.member-preview, .process-preview {
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }

  &:before {
    height: 100%;
    background: #960f12;
    mix-blend-mode: multiply;
    opacity: 0;
    transform: translate3d(0,0,0);
  }

  &:after {
    height: 40px;
    bottom: -50px;
    background: linear-gradient(180deg,transparent,#e6272c);
  }

  &__img {
    @mixin aspect-ratio 376, 376;
    width: 100%;
    position: relative;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.read-more-btn {
  position: absolute;
  color: var(--white);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background: var(--white);
    transition: all 0.3s ease-in-out;
  }
  &:hover {
    &::after {
      width: 100%;
      left: 0;
      background: var(--white);
    }
  }
}

/* Member single page */
.member-info-section {
  padding: 37px 0 70px;
  overflow: visible;
}

.member-box {
  margin-top: -120px;

  .btn-wrap {
    .shadow-btn {
      &:first-of-type {
        margin: 0;
      }
      &:nth-of-type(2) {
        margin-left: -10px;
      }
    }

    @mixin media 1200 {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @mixin tab-md {
    display: flex;
    align-items: center;

    .btn-wrap {
      margin-left: 30px;
    }
  }

  @mixin mob-xl {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    width: 100%;

    .member-avatar {
      max-width: 280px;
      width: 100%;
      height: 100%;

      .member-img {
        @mixin aspect-ratio 490, 490;
      }
    }

    .btn-wrap {
      margin: 0;
    }
  }
}

.avatar {
  position: relative;
  margin-bottom: 24px;
  size: ac(490px, 350px, 1024, 1200);

  &-img {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.avatar-socials {
  position: absolute;
  right: 20px;
  bottom: 20px;

  .social-link {
    size: ac(61px, 40px);
    background: var(--blue-green);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ac(23px, 20px);
    transition: background 0.3s ease;

    &:hover {
      background: var(--red);
    }
  }
}

.member-info {
  max-width: 580px;
  margin-left: 40px;

  .member-name {
    margin-bottom: 5px;
  }

  .member-contacts {
    margin: 38px 0;
  }

  label,
  a {
    display: block;
    font-size: 21px;
    text-transform: none;
  }

  .contact-item {
    display: flex;
    align-items: flex-start;
		flex-wrap: wrap;

    label {
      font-weight: 500;
      margin-right: 5px;
    }

    a {
      font-weight: 300;
      transition: color 0.3s ease;

      &:hover {
        color: var(--red);
      }
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .member-bio {
    label {
      margin-bottom: 7px;
    }

    .inline-content-element {
      a {
        display: inline;
      }

      p:not(:last-child) {
        padding-bottom: 20px;
      }
    }
  }

  @mixin tab-md {
    margin: 0;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .member-box .btn-wrap .shadow-btn:first-of-type {
    margin-bottom: 16px;
  }
}