.register-section {
    padding-top: ac(186px, 150px);
    padding-bottom: 60px;
    min-height: 770px;
    background: var(--white);
    height: 100%;
    display: flex;

    label {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        display: block;
    }
}

.register-steps {
    min-width: 270px;
    max-width: 278px;
    width: 100%;
    grid-row: 1 / 3;

    .filter-dropdown {
        display: flex;
        align-items: center;
        padding: 15px;
        width: 100%;
        transition: 0.3s ease;

        &:hover, &.active {
            background: var(--red);
            color: var(--white);
        }
    }
}

.cv-form__upload {
    .upload-input__file-name {
        width: fit-content !important;
        max-width: ac(280px, 155px);
    }
}

.cv-form__text {
    font-size: ac(24px, 18px);
    max-width: ac(415px, 280px);
    overflow: hidden;
    text-overflow: ellipsis;
}

.register-section__wrap {
    display: grid;
    grid-template-columns: minmax(210px, 278px) 1fr;
    grid-column-gap: 10%;
    height: 100%;

    @mixin media 991 {
        grid-template-columns: 1fr;
        grid-gap: 60px;

        .steps-pagination {
            grid-row: 3;
        }

        .register-steps {
            grid-row: auto;
            max-width: 100%;
        }
    }
}

.steps-pagination {
    grid-row: 2;
    justify-self: end;
}

.btn-prev {
    background: rgb(0 129 153 / 30%);
    clip-path: polygon(0% 0%,100% 0,100% 100%,0% 100%,14% 100%);

    &:before {
        transform: skewX(22deg);
        left: 11px;
    }

    span {
        padding: 0 27px 0 40px;
        color: var(--theme-black);

        &:after {
            transform: none !important;
            right: 0;
        }
    }
}

/* Details */
.details-block {
    margin-bottom: 100px;

    @mixin media 991 {
        margin-bottom: 0;
    }
}

.details-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    .input-item {
        max-width: 100%;
    }

    label {
        color: var(--blue-green);
        margin-bottom: 12px;
    }

    @mixin tab {
        grid-template-columns: 1fr;
    }
}


.register-section__title {
    font-size: ac(24px, 20px);
    margin-bottom: 30px;
    text-transform: capitalize;
}

/* Job preferences */
.job-preferences__form {
    display: flex;

    .input-item {
        max-width: 274px;
    }

    label {
        color: var(--blue-green);
        margin-bottom: 12px;
    }

    .select2-container {
        width: 100% !important;

        .select2-selection__rendered {
            line-height: inherit !important;
        }
    }

    @mixin media 991 {
        justify-content: space-between;

        .input-item {
            max-width: 100%;
        }
    }

    @mixin mob-xl {
        flex-direction: column;
    }
}

/* Confirm */
.confirm-form {
    margin-bottom: 100px;

    .register-section__title {
        margin-bottom: 30px;
    }

    @mixin media 991 {
        margin-bottom: 0;
    }
}

.cv-confirm {
    margin: 43px 0 46px;

    .cv-form__upload {
        max-width: 456px;
    }
}

.user-img-preview {
    size: 85px;
    border-radius: 50%;
    background: var(--grey);
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:hover {
        .user-img-upload {

        }
    }
}

.user-img-upload {
    size: 30px;
    border-radius: 50%;
    background: var(--blue-green);
    color: var(--white);
    font-size: 12px !important;
    transition: 0.25s ease;

    display: flex !important;
    align-items: center;
    justify-content: center;

    bottom: 0;
    position: absolute;
    right: -4px;

    input {
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        margin: 0 !important;
        cursor: pointer;
    }

    &:hover {
        background: var(--blue);
    }
}

/* ---- Profile ---- */

.profile-cv-form {
    .btn {
        font-size: 14px;
    }

    .upload-input {
        max-width: 456px;

        @mixin media 991 {
            max-width: 100%;
        }
    }
}

.user-card {
    background: var(--white);
    box-shadow: 5px 0 15px rgba(0,0,0,.24);
    padding: 20px;

    display: flex;
    align-items: center;

    .user-img-preview {
        size: 108px;
    }

    &__text {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        line-height: 100%;
        margin-left: 26px;

        .user-card__name {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.request-btn {
    width: 314px;
    font-size: 14px;
    clip-path: polygon(0 0,100% 0,90% 100%,0 100%,0 100%);

    span {
        &:after {
            right: 15px;
            transform: skewX(-32deg);
        }
    }

    @mixin media 360 {
        font-size: 12px;
        width: 250px;

        span:after {
            right: 11.5px;
            transform: skewX(-27deg);
        }
    }
}

/* Profile steps */
.profile-crop-image-wr {
    .select2-container {
        max-width: 150px;
    }

    .select2-selection {
        padding: 8px !important;
        height: auto !important;
    }

    .select2-selection--single {
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
    }

}