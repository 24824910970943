@mixin min-media 650 {
  .gallery-slider {
    &.gallery-slider-grid {
      .swiper-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        max-width: 100%;
      }

      .gallery-slider {
        &__item {
          width: 50% !important;
          padding: 8px;

          img {
            transition: all 0.3s ease;
          }

          &:nth-child(1) {
            .photo {
              @mixin aspect-ratio 572, 349;
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 25% !important;

            .photo {
              @mixin aspect-ratio 278, 293;
            }
          }

          &:nth-child(4) {
            .photo {
              @mixin aspect-ratio 572, 272;
            }
          }

          &:nth-child(n + 5) {
            transform: translateY(-17%);
            .photo {
              @mixin aspect-ratio 572, 330;
            }
          }

          &:hover {
            img {
              transform: scale(1.03);
            }
          }
        }
      }

      .nav-btn-prev,
      .nav-btn-next {
        display: none;
      }

      .navigation {
        display: none;
      }

      .swiper-pagination {
        display: none;
      }

      &.collapsed {
        .gallery-slider__item {
          width: fit-content !important;
          &:nth-child(1) {
            .photo {
              &:before {
                padding-bottom: 0;
              }

              img {
                position: relative;
              }
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: fit-content !important;

            .photo {
              &:before {
                padding-bottom: 0;
              }

              img {
                position: relative;
              }
            }
          }

          &:nth-child(4) {
            .photo {
              &:before {
                padding-bottom: 0;
              }

              img {
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}

@mixin media 650 {
  .gallery-slider {
    &.gallery-slider-grid {
      position: relative;
      overflow: visible;

      .gallery-slider__item {
        img {
          transition: all 0.3s ease;
        }

        .photo {
          @mixin aspect-ratio 1, 1;
        }

        &:hover {
          img {
            transform: scale(1.03);
          }
        }
      }

      .swiper-pagination {
        width: 100%;
        position: static;
        margin-top: 25px;
        background: var(--light-grey-2);

        .swiper-pagination-progressbar-fill {
          background: var(--black);
        }
      }

      .navigation {
        bottom: -65px;
      }

      &.collapsed {
        .gallery-slider__item {
          width: fit-content !important;

          &:nth-child(1) {
            .photo {
              &:before {
                padding-bottom: 0;
              }

              img {
                position: relative;
              }
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 100% !important;

            .photo {
              &:before {
                padding-bottom: 0;
              }

              img {
                position: relative;
              }
            }
          }

          &:nth-child(4) {
            .photo {
              &:before {
                padding-bottom: 0;
              }

              img {
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}

.gallery-slider {
  &.gallery-slider-horizontal {
    .gallery-slider {
      &__item {
        width: 50%;
        padding: 8px;

        img {
          transition: all 0.3s ease;
        }

        &:nth-child(1) {
          .photo {
            @mixin aspect-ratio 572, 349;

            @mixin media 650 {
              @mixin aspect-ratio 1, 1;
            }
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 25%;
          .photo {
            @mixin aspect-ratio 278, 349;

            @mixin media 650 {
              @mixin aspect-ratio 1, 1;
            }
          }
        }

        &:hover {
          img {
            transform: scale(1.03);
          }
        }

        @mixin media 650 {
          padding: 0;
        }
      }
    }

    .nav-btn-prev,
    .nav-btn-next {
      display: none;
    }

    .navigation {
      display: none;
    }

    .swiper-pagination {
      display: none;
    }

    &.collapsed {
      .gallery-slider__item {
        width: fit-content !important;
        &:nth-child(1) {
          .photo {
            &:before {
              padding-bottom: 0;
            }

            img {
              position: relative;
            }
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 100% !important;

          .photo {
            &:before {
              padding-bottom: 0;
            }

            img {
              position: relative;
            }
          }
        }

        &:nth-child(4) {
          .photo {
            &:before {
              padding-bottom: 0;
            }

            img {
              position: relative;
            }
          }
        }
      }
    }

    @mixin media 650 {
      overflow: visible;

      .nav-btn-prev,
      .nav-btn-next {
        display: flex;
      }

      .navigation {
        display: flex;
      }

      .swiper-pagination {
        display: flex;
      }

      .swiper-pagination {
        width: 100%;
        position: static;
        margin-top: 25px;
        background: var(--light-grey-2);

        .swiper-pagination-progressbar-fill {
          background: var(--black);
        }
      }

      .navigation {
        bottom: -65px;
      }
    }
  }
}