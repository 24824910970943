/* ---- Sectors page ---- */

/* Why us */
.why-us {
  background: var(--white);
  padding: 100px 0 110px;
  overflow: visible;

  &-info {
    max-width: 670px;
    margin: 0 auto;
    text-align: center;

    h3 {
      color: var(--red);
    }

    .subtext {
      max-width: 475px;
      margin: 20px auto;
    }
  }
}

/* Sector list */
.sectors-list {
  padding: ac(140px, 80px) 0 ac(128px, 80px);

  .sector-card {
    width: 100% !important;
    box-shadow: var(--main-shadow);
    min-height: 310px;

    &:not(:last-child) {
      margin-bottom: 53px;
    }

    .slide-wrap {
      height: 100%;
      opacity: 1;
    }

    .slide-label {
      opacity: 1;
    }

    .sector-icon {
      bottom: 18px;
    }

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-left: 296px;
      max-width: 100%;
      opacity: 1;
      padding-right: 20px;
      padding-bottom: 28px;

      .shadow-btn {
        margin-top: auto;
        margin-left: auto;

        @mixin tab-sm {
          margin: 0;
        }
      }

      .content-element {
        max-height: 225px;
      }
    }
  }
}

/* Sector card */
.sector-card {
  position: relative;
  width: 278px !important;
  height: 314px;
  color: var(--white);
  background: var(--white);
  transition: all 0.2s ease-in-out;
  max-height: 385px;

  .content {
    display: flex;
    flex-direction: column;

    padding: 25px 16px 25px 0;
    position: relative;
    margin-left: auto;
    max-width: 280px;
    height: 100%;
    opacity: 0;
    transition: all 0.2s ease-in-out;

    .content-element {
      max-height: 194px;
      margin: 0 0 18px;
      padding-right: 10px;

      .simplebar-track.simplebar-vertical {
        width: 4px;
        background: var(--white);
      }

      .simplebar-scrollbar::before {
        background-color: var(--popup-dark-blue);
        opacity: 1;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      &::-webkit-scrollbar {
        display: none;
        opacity: 0;
      }
    }

    p {
      font-size: 16px;
      text-transform: none;
      line-height: 1.75;
      color: var(--white);
    }

		@mixin media 650 {
			.content-element {
				max-height: 145px;

				p {
					font-size: 14px;
				}
			}
		}
  }

  .shadow-btn {
    margin-top: auto;
  }

  .slide-wrap {
    height: 100%;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
    background-color: var(--blue-green);
  }

  .slide-label {
    position: absolute;
    top: 21px;
    left: -9px;
    opacity: 0.6;
    transition: all 0.2s ease-in-out;
		transform: translateZ(0);
  }

  .preview {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 278px;
    z-index: 1;

    &-img {
      position: absolute;
      width: 100%;
      height: 100%;
      clip-path: polygon(100% 0, 100% 50%, 40% 100%, 0 100%, 0 0);
    }
  }

  .sector-icon {
    width: 55px;
    height: 55px;
    position: absolute;
    right: 15px;
    bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &.swiper-slide-active {
    width: 572px !important;

    .content, .slide-label, .slide-wrap{
      opacity: 1;
    }
  }
}

.slide-label {
  max-width: ac(260px, 234px);
  width: 100%;
  min-height: ac(80px, 75px);
  padding: 10px ac(28px, 20px) 8px 23px;
  display: flex;
  align-items: center;
  background: var(--theme-black);
  clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%, 0 100%);
  z-index: 10;

  h4, .h4 {
    color: var(--white);
    @mixin max-line-length 3;
  }
}
