.content-hub-section {
  padding-top: ac(106px, 80px);
  padding-bottom: ac(141px, 70px);

  background: url("../images/decor-items/content-hub-decor.svg") no-repeat 100%
    105% / contain;

  &__wrap {
    width: 100%;
    margin: 0 auto;
     display: grid;
    grid-template-columns: ac(238px, 160px) 1fr;
    grid-column-gap: ac(90px, 30px);
    grid-row-gap: ac(90px, 30px);

     @mixin media 990 {
      grid-column-gap: 0;
      grid-row-gap: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &.decor-right-bottom {
    &:before {
      transform: translateX(67.5%) translateY(29%) rotate(-6deg);
    }
  }
}

.content-hub-sidebar {
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    + .content-hub-sidebar__content {
      @mixin min-media 991 {
        padding-top: ac(50px, 40px);
        padding-bottom: ac(90px, 40px);
      }

      @mixin media 450 {
        padding-top: 10px;
      }
    }

    &.is-open {
      .content-hub-sidebar__title:before {
        transform: rotate(0deg) translateY(-50%);
      }
    }

    @mixin media 990 {
      width: 48%;
    }

    @mixin media 450 {
      width: 100%;
    }
  }

  &__title {
    filter: drop-shadow(5px 0px 15px var(--shadow-color));
    color: var(--theme-black);
    background: var(--white);
    clip-path: polygon(0 0%, 100% 0, 87% 100%, 0 100%, 0 100%);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    font-size: ac(16px, 14px);
    line-height: 110%;
    font-weight: 600;
    max-width: ac(238px, 200px);
    position: relative;

    button {
      padding: ac(17px, 15px) 20px ac(17px, 15px) ac(20px, 10px);
      width: 100%;
      text-align: left;
    }

    &:before {
      position: absolute;
      top: 50%;
      right: 40px;
      content: "";
      background-image: url("../images/icons/arrow.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 11px;
      height: 6px;
      transform: translateY(-50%) rotate(-180deg);
      transition: transform 0.3s ease-in-out;

      @mixin min-media 901 {
        display: none;
      }
    }

    @mixin media 900 {
      cursor: pointer;
    }

    &.active {
      &:before {
        transform: translateY(-50%);
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-left: ac(20px, 10px);
    padding-top: ac(22px, 20px);

    @mixin media 990 {
      transition: all 0.3s ease-in-out;
      padding-bottom: 3px;
    }
  }

  &__item {
    cursor: pointer;
    transition: color 0.4s ease-in-out;

    p {
      text-transform: uppercase;
      font-size: ac(16px, 14px);
      font-weight: 500;
      line-height: 125%;
      color: var(--black);
      display: inline;
      transition: color 0.4s ease-in-out;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 1px;
        background: var(--black);
        transition: all 0.4s ease-in-out;
      }
    }

    &:not(:last-child) {
      margin-bottom: ac(13px, 12px);
    }

    &:hover {
      color: var(--red);
     p {
       &:before {
         width: 100%;
         right: auto;
         left: 0;
       }
     }
    }

    &.active {
      color: var(--red);
      p {
        color: var(--red);

       &:before {
          background: var(--red);
          width: 100%;
        }
      }
    }
  }

  &__wrap {
    @mixin media 990 {
      position: static;
      flex-direction: row;
      justify-content: space-between;

      &.md-filters {
        justify-content: flex-start;
        @mixin min-media 551 {
          width: 100%;
          grid-column-gap: 20px;
          .content-hub-sidebar__content {
            max-width: 200px;

            &:last-child {
              width: auto;
              flex: 1;
              max-width: none;
              .content-hub-sidebar__list {
                column-count: 2;
                display: block;

                .content-hub-sidebar__item {
                  max-width: 48%;
                }
              }
            }
          }
        }
      }
    }

    @mixin media 450 {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  @mixin media 990 {
    width: 100%;
  }
}

.sidebar {
  &.md-filters {
    @mixin media 990 {
      @mixin min-media 551 {
        max-width: 100% !important;
      }
    }
  }
}

.content-hub-content {
  &__title-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-bottom: 23px;
    h3 {
      color: var(--blue-green);
      letter-spacing: -0.25px;
      line-height: 110%;
      margin-right: ac(21px, 15px);
      margin-bottom: 20px;
    }
  }

  &__results {
    line-height: 110%;
    margin-bottom: ac(14px, 16px);
  }

  .pagination-list {
    margin-left: ac(65px, 0px);
  }

  @mixin media 1150 {
    width: 100%;
    margin: 0 auto;
  }

  @mixin media 990 {
    padding-top: 30px;
    width: 100%;
    max-width: 100%;
  }
}

.content-hub-list {
  grid-column-gap: ac(30px, 16px);
  grid-row-gap: ac(28px, 16px);
  grid-auto-rows: 1fr;
  display: grid;
  grid-template-columns: repeat(2, minmax(0,1fr));
  padding-bottom: ac(88px, 40px);

  &>h2 {
    white-space: nowrap;
  }

  &.without-tags {
    grid-template-columns: repeat(3, minmax(0,1fr));
  }

  .shadow-wrap {
    &.blue {
      height: ac(122px, 90px);
      top: 2px;
      img {
        height: 100%;
      }
    }
    &.red {
      bottom: -12px;
      height: 178px;
      .triangle {
        clip-path: polygon(0 0, 0 0, 100% 54%, 0 100%);
      }
    }
  }

  .article {
    max-width: 100%;
  }

  .article-desc .date {
    line-height: 138%;
  }

  @mixin media 1150 {
    grid-template-columns: repeat(2, minmax(0,1fr)) !important;
  }

  @mixin media 550 {
    grid-template-columns: minmax(0,1fr) !important;
  }
}

.article-responsive {
  position: relative;
  padding: ac(136px, 102px) 30% ac(25px, 20px) ac(32px, 20px);
  height: auto;
  width: 100%;
  min-height: ac(299px, 200px);
  background: var(--light-gray);

  .list-article-decor-blue-green {
    position: absolute;
    width: ac(65px, 43px);
    height: ac(113px, 87px);
    top: 0;
    left: 0;
    filter: drop-shadow(5px 5px 6px var(--shadow-color));

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      clip-path: polygon(0 0, 25% 0, 100% 54%, 0 100%);
      background: var(--blue-green);
    }
  }

  .list-article-img {
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    height: 100%;
    clip-path: polygon(18% 0, 100% 0, 100% 100%, 97% 100%);

    img {
      width: 100%;
      object-fit: cover;
      height: 80%;
    }

    &:before {
      position: absolute;
      content: "";
      bottom: -1px;
      right: -1px;
      width: 50%;
      height: 56%;
      background: var(--red);
      z-index: 1;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
  }

  .article-desc-category {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 5px;
  }

  .article-desc-text {
    width: 100%;
    h5, .h5 {
      font-size: ac(24px, 18px);
    }
  }

  @mixin media 1150 {
    max-width: 398px;
  }

  @mixin media 990 {
    max-width: 100%;
  }

  @mixin media 1279 {
    padding-right: 40%;
    padding-left: 20px;
  }
}

.article {
  &.article-case-study {
    min-height: ac(240px, 180px);
    overflow: visible;
    position: relative;
    background: var(--white);
    margin-bottom: 20px;

    &:after {
      display: none;
    }

    .list-article-img {
      clip-path: none;
      height: auto;
      position: absolute;
      right: 14px;
      top: 10px;
      bottom: 10px;
      left: 14px;
      width: auto;

      &:before {
        display: none;
      }

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        transition: transform .3s ease;
      }
    }

    .article-btn {
      position: absolute;
      left: -3px;
      bottom: -23px;

      @mixin media 551 {
        bottom: -18px;
      }

      span {
        background-color: var(--red);
        color: var(--white);
        border-color: var(--red);

        &:after {
          background-color: var(--red);
        }
      }

      &:hover {
        span {
          background-color: var(--black);
          border-color: var(--black);

          &:after {
            background-color: var(--black);
          }
        }
      }


    }

    &:hover {
      .list-article-img {
        img {
          transform: scale(0.9);
        }
      }

    }
  }
}
