/* ------------- Styles for Swiper ------------- */
:root {
  --swiper-pagination-bullet-horizontal-gap: 5px;
  --swiper-pagination-bullet-width: 17px;
  --swiper-pagination-bullet-height: 17px;
  --swiper-pagination-bullet-inactive-color: var(--dark-grey);
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  --swiper-pagination-color: var(--dark-grey);
}

/* Для правильної роботи Swiper Safari 11.1 */
.swiper-wrapper {
  left: 0 !important;
}

.navigation {
  display: none;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-btn-prev,
.nav-btn-next {
  width: 46px;
  height: 46px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--theme-black);
  color: var(--theme-black);
  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--white);
    background: var(--theme-black);
  }

  &.swiper-button-disabled {
    color: var(--white);
    background: var(--theme-black);
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.nav-btn-prev {
  transform: rotate(180deg);
  margin-right: 24px;
}

.slider-wrap {
  .swiper-pagination {
    top: auto;
    background: var(--light-grey);

    .swiper-pagination-progressbar-fill {
      background: var(--black);
    }
  }

  .navigation {
    bottom: -125px;
  }
}

.swiper-button-lock {
  display: none;
}

.pagination-main {
	.swiper-pagination-bullet {
		border-radius: 0;
	}

  @mixin min-media 551 {
		bottom: -50px !important;
		left: -5px !important;
		width: fit-content !important;
	}
}
