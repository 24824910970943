.header,
.mobile-header,
.footer {
  .navbar {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin: 0 ac(95px, 20px, 1281, 1440) 0 ac(62px, 20px, 1281, 1440);
    transition: all 0.3s ease;

    a {
      white-space: nowrap;
      font-size: ac(14px, 12px);

      &:not(:last-child) {
        margin-right: ac(24px, 12px);

        @mixin media 1250 {
          margin-right: 14px;
          letter-spacing: -0.5px;
        }

        @mixin media 1150 {
          margin-right: 12px;
        }
      }

      @mixin media 1050 {
        font-size: 12px;
      }
    }

    .sublink-wrap {
      position: relative;
      line-height: 0.5;

      &:hover,
      .sublinks:hover {
        .sublinks {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
        }
      }

      .pop-up {
        display: block;

        .icon-arrow-down {
          top: 45%;
        }
      }
    }

    .sublinks {
      position: absolute;
      z-index: 10;
      background: var(--white);
      padding: 14px 10px 17px;
      display: flex;
      flex-direction: column;
      top: 28px;
      box-shadow: var(--main-shadow);
      left: -10px;
      transform: translateY(20px);
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      a {
        font-weight: 400;
      }
    }
  }

  a {
    flex: 0 0 auto;
    position: relative;
  }
}

.links,
.footer {
  a:hover {
    color: var(--red);
  }
}

/* Header */
.header {
  position: absolute;
  background: var(--white);
  padding: 24px 0 16px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: top 0.2s ease-in-out;
  box-shadow: var(--main-shadow);

  a {
    &:not(.btn, .btn-ordinary, .icon, .logo, .search-result__link) {
      display: inline-flex;
      align-items: center;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 2px;
        background: var(--red);
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        color: var(--red);
        &::after {
          width: 100%;
          left: 0;
          background: var(--red);
        }
      }
    }
  }

  .logo {
    max-width: ac(145px, 106px);

    @mixin media 1150 {
      max-width: 106px;
    }
  }

  .container {
    max-width: 1340px;
    width: perc(1340);

    @mixin media 1150 {
      @mixin min-media 1026 {
        width: 95%;
      }
    }

    @mixin media 1025 {
      width: 100%;
      padding: 0 ac(35px, 15px, 320, 375);
    }
  }

  .header-wrapper {
    display: flex;
    align-items: center;

    @mixin media 1151 {
      justify-content: space-between;
    }
  }

  .contact-block,
  .contact-wrap {
    display: flex;
    align-items: center;

    .links {
      max-width: 150px;
      text-align: right;
      margin-right: 0;
      transition: all 0.3s ease;
    }

    .contact-btn {
      width: 150px;
      margin-left: 15px;
      background: var(--white);

      @mixin media 1150 {
        margin-left: 10px;
      }
    }
  }

  .contact-wrap {
    margin-left: auto;

    @mixin media 1281 {
      margin: 0;
    }
  }

  .contact-block {
    width: 100%;

    @mixin media 1281 {
      justify-content: flex-end;
    }

    @mixin media 1151 {
      width: fit-content;
    }

    @mixin tab {
      display: none;
    }
  }

  /* Search block */
  .search-block {
    position: relative;
  }

  .search-result {
    position: absolute;
    width: 100%;
    background: var(--white);
    box-shadow: var(--main-shadow);

    top: 40px;
    max-height: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: fit-content;
    transition: all 0.3s ease;
    z-index: 100;

    &.open {
      max-height: 180px;
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      background: var(--light-gray);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--theme-black);
    }

    &__item {
      padding: 10px;

      .job-name {
        font-size: 14px;
      }

      .icon-arrow-next {
        color: var(--red);
        margin-left: 8px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--light-grey);
      }

      &:hover {
        background: var(--light-grey);
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .search-trigger {
    position: relative;
    transition: all 0.3s ease;
    size: 30px;

    display: flex;
    align-items: center;

    .job-search-input {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      opacity: 0;
      pointer-events: none;
      font-size: 14px;
      padding-right: 40px;

      @mixin media 1050 {
        padding-left: 8px;
        padding-right: 30px;
      }

      &::placeholder {
        font-size: 14px;

        @mixin media 1150 {
          font-size: 12px;
        }
      }

      &:focus {
        border-color: var(--red);
      }
    }

    .search-btn {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%,-50%);
      flex: 0 0 auto;
      font-size: 18px;
      transition: all 0.3s ease;
      z-index: 20;

      i, span {
        transition: all 0.3s ease;
      }

      &:hover {
        color: var(--red);
      }
    }

    .icon-close {
      display: none;
    }

    @mixin media 1281 {
      margin-left: auto;
    }
  }

  /* Open search input */
  &.open-search {
    .navbar {
      margin-right: ac(40px, 16px, 1280, 1440);
    }

    .search-trigger {
      width: 100%;
    }

    .job-search-input {
      opacity: 1;
      pointer-events: auto;
    }

    .search-btn {
      .icon-search {
        display: none;
      }

      .icon-close {
        display: block;
      }
    }

    .links {
      opacity: 0;
      margin-right: -150px;
    }
  }

  .menu-btn {
    display: none;

    @mixin media 1025 {
      display: block;
    }
  }

  &:not(.burger-on-desktop) {
    .burger {
      display: none;
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
  }

  .logo {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.links {
  a {
    text-transform: none;
  }
}

/* Mobile header */
.mobile-header {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  right: -100%;
  bottom: 0;
  padding: 140px ac(36px, 15px, 320, 375) 28px;
  background: var(--light-grey);
  text-align: center;
  overflow-y: scroll;
  display: none;
  transition: all 0.3s ease-in-out;

  a {
    &:not(.btn-ordinary, .links a, .sublinks a) {
      font-size: ac(30px, 25px, 375, 1025);
      width: fit-content;
    }
  }

  &.active {
    right: 0;
  }

  .mobile-navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 77px;

    .pop-up-link {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: fit-content;
      margin: 0 auto;

      .sublinks-trigger {
        size: 12px;
        transform: translate(10px);

        button {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
        }

        .icon-arrow-down {
          font-size: 7px;
        }
      }
    }

    a {
      font-size: 16px;
      &:hover {
        color: var(--red);
      }

      &:not(:last-child, .sublinks a, .pop-up-link a) {
        margin-bottom: 21px;
      }
    }

    .sublink-wrap {
      margin-bottom: 21px;

      &.is-open {
        .pop-up-link {
          a,
          i {
            color: var(--red);
          }
        }
      }
    }

    .sublinks {
      a {
        display: inherit;
        font-weight: 400;
        margin-top: 11px;
      }
    }
  }

  .details {
    max-width: 600px;
    margin: 0 auto;
  }
}

.sign-wrap,
.mobile-search {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn-ordinary {
    font-size: 12px;
  }
}

.mobile-search {
  margin-bottom: 20px;

  input {
    background: var(--white) !important;
    border: none !important;
    box-shadow: var(--main-shadow) !important;
    margin-right: 12px;
    height: 46px;

    &::placeholder { 
      font-size: 12px;
    }
  }

  .btn-ordinary {
    flex: 0 0 auto;
    height: 46px;
    width: 80px;
  }
}

.sign-wrap {
  .btn-ordinary {
    width: 140px;
    height: 46px;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;

  background: rgba(0, 0, 0, 0.7);

  pointer-events: none;
  visibility: hidden;
  opacity: 0;

  transition: all 0.3s ease-in-out;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}

/* Footer */
.footer {
  background: var(--theme-black);
  padding: ac(115px, 70px) ac(140px, 36px) ac(72px, 40px) ac(140px, 36px);

  a:hover {
    color: var(--red) !important;
  }

  &-wrap {
    display: flex;
  }

	&-cont {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 20px;

		.btn {
			align-self: center;
		}

		.logo, .btn {
			margin-bottom: ac(70px, 15px);
		}

		a:not(.btn) {
			font-size: ac(18px, 16px);
			font-weight: 300;
			color: var(--white);
			text-transform: none;
		}

		@mixin media 470 {
			.logo, .btn {
				grid-column: -1 / 1;
			}
		}

		@mixin media 401 {
			.logo {
				img {
					width: 80%;
				}
			}

			.contact-list {
				grid-column: -1/1;
			}
		}
	}

  .logo-block {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .logo {
    &:first-child {
      max-width: ac(196px, 145px);
      margin-right: 20px;

      @mixin mob-sm {
        margin-right: 0;
      }
    }

    &:last-child {
      max-width: 128px;
    }
  }

  &-content {
    display: flex;
    max-width: 572px;
  }

  .subtext {
    margin-bottom: 8px;
    color: var(--white);
  }

  .contact-list {
    li {
      max-width: 170px;

      a {
        line-height: 165%;
      }

      &:not(:last-child) {
        margin-bottom: 22px;
      }
    }
  }

  .other {
    .subtext {
      margin-bottom: 10px;
    }

    a {
      color: var(--white);
    }

    .sitemap {
      width: 125px;
    }
  }

	@mixin min-media 1441 {
		width: 100%;
		max-width: 830px;
	}
}

.socials {
  margin-bottom: 18px;

  &-links {
    display: flex;
    align-items: center;
    a {
      font-size: 26px;
      transition-duration: 0.2s;
      &:hover {
        transform: scale(1.1);
        color: var(--red);
      }
    }
    li:not(:last-child) {
      margin-right: 28px;
    }
  }
}

.sitemap {
  a {
    &:hover {
      color: var(--red);
    }
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  &-links {
    display: flex;
    flex-direction: column;
  }
}

.copyright {
  margin-top: ac(75px, 38px);
  p,
  a {
    font-size: ac(14px, 12px);
    color: var(--light-grey);
  }
  a {
    text-transform: none;
    font-weight: 300;
  }

  .privacy-links {
    margin-top: ac(8px, 24px);
  }
}

/* Responsive */

@mixin media 1151 {
  .footer {
    &-wrap {
      flex-direction: column;
    }
    &-content {
      justify-content: space-between;
      max-width: 700px;
    }
  }

  /*.search-block {
    display: none;
  }*/
  .contact-btn {
    margin-left: 0;
  }
}

@mixin media 1025 {
  .header {
    &-wrapper {
      justify-content: space-between;
    }
    .contact-btn {
      display: none;
    }
    .navbar {
      margin-right: 0;
    }
  }
}

@mixin media 1025 {
  .mobile-header {
    display: block;
  }

  .search-block {
    display: none;
  }

  .header {
    .navbar {
      display: none;
    }
    .container {
      width: 100%;
    }
  }
}

@mixin media 551 {
  .footer-content {
    flex-direction: column;
  }
  .contact-list {
    margin-bottom: 24px;
  }
  .header .btn.sm {
    width: 104px;
    span:after {
      right: 5.5px;
      width: 2px;
      transform: skewX(-20deg);
    }
  }
}

@mixin media 361 {
  .sign-wrap {
    flex-direction: column;
    .links {
      align-items: center;
      margin-top: 20px;
    }
  }
}
