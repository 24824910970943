.interior-page {
    &.join-the-team {
        padding-top: ac(115px, 80px);

        .offer h1 {
            line-height: 120.93%;
            margin-bottom: 20px;
        }

        .offer-text {
            line-height: 140%;
        }
    }
}

.why-us-with-video {
    padding-top: ac(102px, 80px);
    padding-bottom: ac(248px, 220px);

    .why-us-info {
        margin-bottom: ac(80px, 40px);

        h3 {
            letter-spacing: -0.25px;
        }

        .subtext {
            margin: 15px auto;
        }

        .text-content {
            p, a, li {
                line-height: 172.22%;
            }
        }
    }

    .video-player {
        margin-bottom: ac(-470px, -300px);
        z-index: 1;
    }
}

.benefits-section {
    padding-top: ac(360px, 140px);
    padding-bottom: ac(138px, 70px);

    &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @mixin media 901 {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }

    &__info {
        width: 40.86%;

        .text-content {
            p, a, li {
                line-height: 172.22%;
            }
        }

        @mixin media 901 {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 8px;
        letter-spacing: -0.25px;
    }

    &__subtitle {
        margin-bottom: ac(5px, 10px);
    }

    &__list {
        padding-top: 32px;
        max-width: 690px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: ac(-50px, -25px);
        transform: translateX(ac(80px, 0px));
        width: 60%;

        @mixin media 901 {
            padding-top: 40px;
            width: 100%;
            transform: translateX(0);
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 0 14px ac(48px, 30px);
        max-width: 210px;

        @mixin media 901 {
            max-width: 250px;
        }
    }

    &__logo {
        max-width: 66px;
        width: 100%;
        margin-bottom: 16px;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    &__text {
        text-align: center;
        text-transform: uppercase;
        color: var(--theme-black);

        p {
            font-size: ac(21px, 16px);
            font-weight: 400;
            line-height: 133.33%;
            letter-spacing: -0.25px;
        }
    }
}

.join-team-latest-jobs-section {
    padding-top: ac(136px, 70px);
    padding-bottom: ac(70px, 35px);
    box-shadow: none;

    .heading h3 {
        margin-bottom: 10px;
    }

    .subtext {
        margin-left: ac(13px, 0px);
    }

    .input-item {
        max-width: 355px;
    }

    .grid-two-wrapper {
        grid-row-gap: ac(30px, 20px);
    }

    .jobs-list {
        margin: 38px 0 ac(48px, 38px);
    }
}

.gallery-section {
    padding-top: ac(60px, 35px);
    padding-bottom: 75px;

    .gallery-slider {
        width: calc(100% + 16px);
        margin-left: -8px;
    }

    @mixin media 650 {
        padding-bottom: 120px;

        .gallery-slider {
            width: 100%;
            margin-left: 0;
        }
    }
}