:root {
  --plyr-color-main: #e6272c;
  --plyr-control-icon-size: 25px;
}

.plyr__poster {
	background-size: cover;
}

.plyr__video-wrapper {
  z-index: 0;
}



