h1,
h2,
h3,
h4, .h3, .h4 {
  font-weight: 400;
  text-transform: uppercase;
}

b, strong {
  font-weight: 600 !important;
}

h1 {
  color: var(--white);
  font-size: ac(86px, 33px);
  line-height: 1.4;
}

h2 {
  font-size: ac(50px, 44px);
}

h3, .h3 {
  font-size: ac(32px, 22px);
  line-height: 135%;
}

h4, .h4 {
  font-size: ac(25px, 21px);
  line-height: 130%;
}

h5, .h5 {
  font-size: ac(24px, 20px);
  font-weight: 300;
  line-height: initial;
}

p {
  font-size: ac(18px, 16px);
  font-weight: 300;
  line-height: 155%;
}

.subtext {
  font-size: ac(21px, 18px);
  font-weight: 500;
  line-height: 145%;
  color: var(--theme-black);
}

a {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: var(--black);
  line-height: 150%;
  transition: all 0.3s ease-in-out;

  .icon-arrow-down {
    display: inline-block;
    font-size: 7px;
    transform: translateX(5px);
  }
}

span {
  display: block;
}

span, strong {
  line-height: 145%;
}

a,
button {
  text-transform: uppercase;
  font-family: inherit;
}

.content-element {
  * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    padding: 0;
    margin-bottom: 45px;
  }

  li,
  td {
    font: 300 ac(18px, 16px) / 171% var(--font-main);

    a {
      font: inherit;
      font-weight: 400;
      position: relative;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: var(--red);
        transition: color 0.3s ease-in-out;
      }
    }
  }

	li {
		width: fit-content;
	}

  ul,
  ol,
  li {
    &:not(:last-child) {
      padding-bottom: ac(11px, 10px);
    }

    + h1, h2, h3 {
      padding-top: ac(22px, 15px);
    }
  }

  ul {
    list-style: none;
    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: ac(9px, 6px);
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: var(--theme-black);
      }
    }
  }

  ol {
    list-style-type: none;
    counter-reset: num;

    li {
      padding-left: 20px;
      position: relative;

      &:before {
        position: absolute;
        color: var(--theme-black);
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-family: var(--font-main);
        font-size: inherit;
        font-weight: 500;
        line-height: 100%;
        content: counter(num) ".";
        counter-increment: num;
      }
    }
  }

  img {
    border-radius: ac(40px, 20px);
    margin-top: ac(20px, 10px);
    margin-bottom: ac(76px, 35px);
  }
}

@mixin mob-sm {
  h1 {
    font-size: 30px;
  }
}
