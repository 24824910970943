.insight-section {
  background: var(--white);
}

.insight-section__wrap {
  width: 100%;
  margin: 0 auto;
  display: flex;

  @mixin media 901 {
    grid-column-gap: 0;
    grid-row-gap: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.sidebar {
  position: relative;
  min-height: 100%;
  width: 100%;
  flex: 0 0 auto;
  max-width: ac(400px, 280px);

  &__wrap {
    position: sticky;
    top: 140px;
    bottom: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.insight-sidebar {
  margin-right: 70px;
  
  @mixin media 1100 {
    margin-right: 40px;
  }

  @mixin media 901 {
    margin-right: 0;
    max-width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    @mixin tab-md {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }

    @mixin tab-sm {
      grid-template-columns: 1fr;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    display: block;

    + .insight-sidebar__content {
      @mixin min-media 901 {
        padding-top: ac(80px, 40px);
      }

      @mixin media 650 {
        padding-top: 35px;
      }
    }

    @mixin tab-md {
      margin-bottom: 50px;
    }

    @mixin media 650 {
      width: 100%;
    }
  }

  &__title {
    font-size: ac(24px, 20px);
    font-weight: 300;
    line-height: 108.33%;
    color: var(--black);
    margin-bottom: ac(25px, 20px);
    text-transform: none;
  }

  &__wrap {
    position: sticky;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    max-height: 100vh;
    padding: 176px 20px 60px 0;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    @mixin media 901 {
      position: static;
      overflow: visible;
      padding: 0;
      max-height: 100%;
    }

    @mixin media 650 {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  @mixin media 901 {
    order: 2;
    width: 100%;
  }
}

.insight-related-card {
  background: var(--light-gray);
  color: var(--black);
  text-transform: none;
  box-shadow: var(--main-shadow);
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
  min-height: 125px !important;
  height: auto !important;
  overflow: hidden;
  padding: 0 ac(55px, 25px) 0 84px !important;
  max-width: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .list-article-img,
  .article-desc-category {
    opacity: 0;
    transition: all 0.3s ease;
  }

  .article-desc-category {
    height: 0;
    text-transform: uppercase;
  }

  .article-desc-text {
    transition: margin 0.3s ease;

    h5, .h5 {
      @mixin max-line-length 3;
    }
  }

  .article-desc {
    max-width: 100% !important;
  }

  h5, .h5 {
    font-size: ac(24px, 20px);
    font-weight: 300;
    line-height: 122%;
  }

  &:not(:last-child) {
    margin-bottom: ac(30px, 25px);
  }

  &:hover {
    min-height: 300px !important;
    padding: ac(136px, 102px) 40% ac(25px, 20px) ac(32px, 20px) !important;

    .article-desc-text {
      margin-top: 0;
    }

    .article-desc-category {
      height: auto;
    }

    .list-article-img,
    .article-desc-category {
      opacity: 1;
    }
  }

  @mixin tab-md {
    max-width: 100%;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.insight-latest-jobs {
  .job-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ac(29px, 28px) ac(60px, 40px) ac(28px, 15px) ac(25px, 15px);
    min-height: 205px;

    h5, .h5 {
      padding-bottom: 15px;
    }

    &:not(:last-child) {
      margin-bottom: ac(30px, 25px);

      @mixin tab-md {
        margin-bottom: 0;
      }
    }

    .job-details {
      margin-left: 0;

      p {
        line-height: 150%;
        display: flex;
        align-items: baseline;
      }

      i {
        margin-top: 0;
      }
    }

    .icon-arrow-next {
      top: auto;
      right: 24px;
      bottom: 33px;
      transform: none;
    }
  }
}

.insight-content {
  padding-top: 176px;
  padding-bottom: ac(105px, 50px);

  &__title {
    margin-bottom: 20px;
    h1,
    h2,
    h3 {
      font-size: ac(32px, 22px);
      line-height: 122%;
      color: var(--blue-green);
      letter-spacing: -0.25px;
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: ac(28px, 25px);
  }

  &__author {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  &__author-photo {
    @mixin aspect-ratio 1, 1;
    width: ac(40px, 35px);
    border-radius: 50%;
    margin-right: ac(15px, 10px);
  }

  &__author-info {
    p {
      font-size: ac(16px, 14px);
      font-weight: 500;
      line-height: 130%;
    }
  }

  &__date {
    font-size: ac(16px, 14px);
    font-weight: 300;
    line-height: 130%;
  }

  &__photo {
    width: fit-content;
    box-shadow: var(--main-shadow);
    margin-bottom: 25px;
    -webkit-mask-image: none;
    mask-image: none;

  }

  .inline-content-element {
    table {
      max-width: 500px;
      width: 100% !important;
    }
    &.insight-text-styles {
      h1,
      h2,
      h3 {
        color: var(--red) !important;
      }
    }
  }

  &__article {
    padding-bottom: ac(46px, 40px);
  }
}

.insight-latest-jobs-section {
  padding-top: ac(143px, 70px);
  padding-bottom: ac(148px, 70px);

  .heading {
    margin-bottom: 35px;
    h3 {
      margin-bottom: 13px;
    }
  }

  .subtext {
    line-height: 140%;
    margin-left: 14px;
  }

  .job-item {
    padding-bottom: ac(22px, 15px);

    .job-details p {
      line-height: 155%;
    }
  }

  .grid-two-wrapper {
    grid-row-gap: ac(30px, 25px);
  }
}
