.multiple-select {
    position: relative;



    &.open {
        .dropdown {
            display: block;
        }

        .multiple-select__mask {
            display: block;
        }
    }

    &__mask {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
    }

    .dropdown {
        position: absolute;
        left: 0;
        top: 100%;
        padding: 12px;
        width: 100%;
        background: var(--white);
        color: var(--theme-black);

        transition: 0.3s ease-in;

        max-height: 250px;
        overflow-y: auto;

        display: none;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--theme-black);
        }

        .checkBox {
            width: 100%;

            span {
                color: var(--theme-black);
                padding-left: 20px;
            }
        }

        .checkBox {
            padding: 5px;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: var(--light-gray);
            }
        }

        .filters-parent  {
            margin-bottom: 0;

            &--inner {
                padding: 0 0 0 5px;
            }

            .filters-trigger {
                margin: 0 8px 0 0;
            }
        }

        .filters-children {
            padding: 0 0 0 30px;
        }
    }
}